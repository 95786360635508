export const ERROR_CODES_GLOBAL = {
	AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
	REQUEST_INPUT_ERROR: 'REQUEST_INPUT_ERROR',
	ERR_CONNECTION_REFUSED: 'ERR_CONNECTION_REFUSED',
	ERR_CONNECTION_SSL_REFUSED: 'ERR_CONNECTION_SSL_REFUSED',
	UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
};

export const ERROR_CODES_INTERNAL = {
	INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
	INVALID_TOKEN_IN_REQUEST: 'INVALID_TOKEN_IN_REQUEST',
	OBJECT_NOT_FOUND: 'OBJECT_NOT_FOUND',
	INVALID_PARAMETERS: 'INVALID_PARAMETERS',
	NAME_ALREADY_IN_USE: 'NAME_ALREADY_IN_USE',
	INVALID_OBJECT_STATE: 'INVALID_OBJECT_STATE',
	MARKETPLACE_USER_ROLE_REQUIRED: 'MARKETPLACE_USER_ROLE_REQUIRED',
	NOT_PUSHED_TO_PROD: 'NOT_PUSHED_TO_PROD',
};

const ERROR_CODES = {
	INTERNAL: ERROR_CODES_INTERNAL,
	GLOBAL: ERROR_CODES_GLOBAL,
};

export default ERROR_CODES;
