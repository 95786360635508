import { RdvFileFieldValue } from '@aquga/components/services/types';
import { PaymentMethodDetails } from '@aquga/pages/tickets/TicketDetailsPage';
import { Stack } from '@mui/material';

import { MatchDetails } from './MatchDetails';
import { MatchOptions } from './MatchOptions';

interface MatchDetailsDrawerContentProps {
	match: any;
	consultingExpertiseTitle: string;
	consultingExpertiseDescription: string;
	rawMatchData: any;
	ticketNumber: number;
	contract: any;
	contractDeclinedReason: string;
	transactionId: string;
	loading: boolean;
	solutionDocuments: any;
	generatedContract: RdvFileFieldValue;
	requestedChangesFromMatch: string;
	supplyTechnology: string[];
	supplyBusinessArea: string[];
	supplyTechnologyOther: string;
	supplyBusinessAreaOther: string;
	supplyAdditionalDocs: any[];
	supplyAdditionalNotes: string;
	contactPerson: string;
	contactNumber: string;
	contactEmail: string;
	payment_metod_details: PaymentMethodDetails;
	totalBeforeTax: number;
	tax: number;
	totalToPay: number;
}

export const MatchDetailsDrawerContent = ({
	rawMatchData,
	solutionDocuments,
	transactionId,
	ticketNumber,
	match,
	contract,
	generatedContract,
	loading,
	consultingExpertiseTitle,
	contractDeclinedReason,
	consultingExpertiseDescription,
	requestedChangesFromMatch,
	supplyTechnology,
	supplyBusinessArea,
	supplyTechnologyOther,
	supplyBusinessAreaOther,
	supplyAdditionalDocs,
	supplyAdditionalNotes,
	contactPerson,
	contactNumber,
	contactEmail,
	payment_metod_details,
	totalBeforeTax,
	tax,
	totalToPay,
}: MatchDetailsDrawerContentProps) => {
	return (
		<Stack spacing={2}>
			<MatchOptions transactionId={transactionId} loading={loading} status={match.status} />
			<MatchDetails
				contractDeclinedReason={contractDeclinedReason}
				requestedChangesFromMatch={requestedChangesFromMatch}
				solutionDocuments={solutionDocuments}
				contract={contract}
				consultingExpertiseTitle={consultingExpertiseTitle}
				consultingExpertiseDescription={consultingExpertiseDescription}
				rawMatchData={rawMatchData}
				match={match}
				ticketNumber={ticketNumber}
				loading={loading}
				contactEmail={contactEmail}
				generatedContract={generatedContract}
				supplyTechnology={supplyTechnology}
				supplyBusinessArea={supplyBusinessArea}
				supplyTechnologyOther={supplyTechnologyOther}
				supplyBusinessAreaOther={supplyBusinessAreaOther}
				supplyAdditionalDocs={supplyAdditionalDocs}
				supplyAdditionalNotes={supplyAdditionalNotes}
				contactPerson={contactPerson}
				contactNumber={contactNumber}
				payment_method_details={payment_metod_details}
				totalBeforeTax={totalBeforeTax}
				tax={tax}
				totalToPay={totalToPay}
			/>
		</Stack>
	);
};
