import { AQUGA_NATURAL_10, AQUGA_NATURAL_60, AQUGA_NATURAL_90 } from '@aquga/styles/theme';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { useIntl } from 'react-intl';

interface TabLabelProps {
	text: string;
	active: boolean;
}
const TabLabel = ({ text, active }: TabLabelProps) => {
	return (
		<Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
			<Typography
				sx={{
					textTransform: 'none',
					color: active ? AQUGA_NATURAL_90 : AQUGA_NATURAL_60,
				}}
				variant="subtitle2"
			>
				{text}
			</Typography>
		</Stack>
	);
};

export enum ParticipantAccountTabKind {
	finances = 'finances',
	general = 'general',
}

interface ParticipantAccountTabsProps {
	selected: ParticipantAccountTabKind;
	isParticipantBussinesSide: boolean;
	onClick: (selectedTab: ParticipantAccountTabKind) => void;
}
const ParticipantAccountTabs = ({
	selected = ParticipantAccountTabKind.general,
	isParticipantBussinesSide,
	onClick,
}: ParticipantAccountTabsProps) => {
	const intl = useIntl();
	return (
		<Box sx={{ backgroundColor: AQUGA_NATURAL_10, pl: 1.5, pr: 1.5, borderRadius: 3 }}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				sx={{ pl: 2, pr: 2, pb: 0, width: '100%' }}
			>
				<Tabs value={selected} variant="scrollable" allowScrollButtonsMobile>
					<Tab
						value="general"
						onClick={() => onClick(ParticipantAccountTabKind.general)}
						label={
							<TabLabel text={intl.formatMessage({ id: 'participantAccountPage.tabs.general' })} active />
						}
					/>
					{isParticipantBussinesSide && (
						<Tab
							value="finances"
							onClick={() => onClick(ParticipantAccountTabKind.finances)}
							label={
								<TabLabel
									text={intl.formatMessage({ id: 'participantAccountPage.tabs.finances' })}
									active
								/>
							}
						/>
					)}
				</Tabs>
			</Stack>
		</Box>
	);
};

export default ParticipantAccountTabs;
