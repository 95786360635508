import { initPointOfContactFormValues, isBusinessSide, isConsultingSide } from '@aquga/app/participantHelpers';
import PageHeader from '@aquga/components/common/PageHeader';
import RdvLoadingButton from '@aquga/components/common/RdvLoadingButton';
import RdvLoadingTextButton from '@aquga/components/common/RdvLoadingTextButton';
import CompanyInfoSection from '@aquga/components/participantAccount/CompanyInfoSection';
import ParticipantAccountTabs, {
	ParticipantAccountTabKind,
} from '@aquga/components/participantAccount/ParticipantAccountTabs';
import PaymentDetailsSection from '@aquga/components/participantAccount/PaymentDetailsSection';
import PersonalInfoSection from '@aquga/components/participantAccount/PersonalInfoSection';
import PointOfContactSection from '@aquga/components/participantAccount/PointOfContactSection';
import TransferDialog from '@aquga/components/participantAccount/transfers/TransferDetailsDialog';
import TransfersTable from '@aquga/components/participantAccount/transfers/TransfersTable';
import { IntegrationProviders, MerchantAccount } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import {
	loadCurrentParticipant,
	selectCurrentParticipant,
	selectUpdatingParticipantFields,
} from '@aquga/store/slices/participantSlice';
import {
	linkParticipantAccountToStripeConnectAccount,
	loadMyTransfers,
	requestPayout,
	requestStripeDashboardLink,
	selectLoading,
	selectLoadingConnectToStripeAccount,
	selectLoadingRequestPayout,
	selectRequestingMerchantAccountExternalUrl,
	selectTransfers,
	selectTransfersPageInfo,
} from '@aquga/store/slices/paymentSlice';
import { Alert, AlertTitle, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

export enum PaginationOperationKind {
	before,
	after,
}
export const DEFAULT_PAGE_SIZE = 20;

const ParticipantAccountPage = () => {
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const intl = useIntl();
	const updatingParticipantFields = useAppSelector(selectUpdatingParticipantFields);
	const isParticipantBussinesSide = isBusinessSide(currentParticipant);
	const transfersPageInfo = useAppSelector(selectTransfersPageInfo);

	const [transferDetails, setTransferDetails] = useState<{ idTransfer: string | null; open: boolean }>({
		idTransfer: null,
		open: false,
	});

	const handleOpenTransferDetailsDialog = (id: string) => {
		setTransferDetails({ idTransfer: id, open: true });
	};
	const handleCloseTransferDetailsDialog = () => {
		setTransferDetails({ open: false, idTransfer: null });
	};

	const payment_type = currentParticipant?.account?.data?.['STRIPE']?.virtual_bank_account?.type;
	const iban =
		currentParticipant?.account?.data?.['STRIPE']?.virtual_bank_account?.financial_addresses[0]?.iban?.iban;
	const bic = currentParticipant?.account?.data?.['STRIPE']?.virtual_bank_account?.financial_addresses[0]?.iban?.bic;
	const country = currentParticipant?.account?.data?.['STRIPE']?.virtual_bank_account?.country;

	const currentTabSearchParam =
		searchParams.get('currentTab') == ParticipantAccountTabKind.finances
			? ParticipantAccountTabKind.finances
			: ParticipantAccountTabKind.general;
	const [currentTab, setCurrentTab] = useState<ParticipantAccountTabKind>(currentTabSearchParam);

	const transfers = useAppSelector(selectTransfers);
	const loadingTransfers = useAppSelector(selectLoading);

	const stripeMerchantAccount: MerchantAccount | undefined = currentParticipant?.account?.merchant_accounts?.find(
		(mac: MerchantAccount) => mac.payment_provider_kind === IntegrationProviders.Stripe
	);
	const connectingToStripe: any = useAppSelector(selectLoadingConnectToStripeAccount); //useAppSelector(selectConnectToStripeAccountStatus);

	const isProvider = isConsultingSide(currentParticipant);

	const requiresFurtherStripeOnboarding = isProvider && stripeMerchantAccount?.data?.details_required;
	const merchantAccountAtStripeOpened = isProvider && stripeMerchantAccount?.data?.approved;

	const promptToOpenMerchantAccountAtStripe =
		isProvider && !merchantAccountAtStripeOpened && !stripeMerchantAccount && !requiresFurtherStripeOnboarding;

	const loadingRequestingMerchantAccountExternalUrl = useAppSelector(selectRequestingMerchantAccountExternalUrl);
	const loadingRequestPayout = useAppSelector(selectLoadingRequestPayout);

	const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
	const [paginationCursor, setPaginationCursor] = useState<string | null>(null);
	const [paginationOperation, setPaginationOperation] = useState<PaginationOperationKind>(
		PaginationOperationKind.after
	);

	const pageStartCursor = transfersPageInfo?.start_cursor ?? null;
	const pageEndCursor = transfersPageInfo?.end_cursor ?? null;

	useEffect(() => {
		dispatch(
			loadMyTransfers({
				...(paginationOperation == PaginationOperationKind.after && { first: DEFAULT_PAGE_SIZE }),
				...(paginationOperation == PaginationOperationKind.before && { last: DEFAULT_PAGE_SIZE }),
				...(paginationCursor &&
					paginationOperation == PaginationOperationKind.after && { after: paginationCursor }),
				...(paginationCursor &&
					paginationOperation == PaginationOperationKind.before && { before: paginationCursor }),
			})
		);
	}, [dispatch, currentPageIndex, paginationOperation, paginationCursor]);

	useEffect(() => {
		dispatch(loadCurrentParticipant());
	}, [dispatch]);

	const handleClickTab = (selectedTab: ParticipantAccountTabKind) => {
		searchParams.set('currentTab', selectedTab);
		setCurrentTab(selectedTab);
		setSearchParams(searchParams);
	};

	const handleConnectToStripeConnect = async () => {
		await dispatch(linkParticipantAccountToStripeConnectAccount());
	};
	const handleGoToStripeDashboard = async () => {
		await dispatch(requestStripeDashboardLink({ id_merchant_account: stripeMerchantAccount?.id ?? '' }));
	};

	const handleRequestPayout = async () => {
		await dispatch(
			requestPayout({
				id_merchant_account: stripeMerchantAccount?.id ?? '',
			})
		);
	};

	const handleNextTransfersPage = () => {
		setPaginationCursor(pageEndCursor);
		setPaginationOperation(PaginationOperationKind.after);
		setCurrentPageIndex((previous) => previous + 1);
	};
	const handlePreviosTransfersPage = () => {
		setPaginationCursor(pageStartCursor);
		setPaginationOperation(PaginationOperationKind.before);
		setCurrentPageIndex((previous) => previous - 1);
	};

	const currentTransfer = transfers?.find((transfer) => transfer.id == transferDetails.idTransfer) as any; //FIXME @Rokva REMOVE ANY
	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'participantAccountPage.metaTitle' })} - AQUGA</title>
			</Helmet>

			{transferDetails.open && transferDetails.idTransfer && (
				<TransferDialog
					open={transferDetails.open}
					transfer={currentTransfer}
					onClose={handleCloseTransferDetailsDialog}
				/>
			)}
			<PageHeader title={intl.formatMessage({ id: 'participantAccountPage.title' })} />
			<br />
			<Stack direction={{ xs: 'column' }} gap={2} sx={{ width: '100%' }}>
				<ParticipantAccountTabs
					isParticipantBussinesSide={isProvider}
					selected={currentTab}
					onClick={handleClickTab}
				/>
				{currentTab == 'general' && (
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12} md={6}>
							<Stack spacing={2}>
								<PersonalInfoSection
									participantFields={currentParticipant?.fields ?? []}
									loading={updatingParticipantFields === 'personalInfo'}
								/>
								<PointOfContactSection
									initialFormValues={initPointOfContactFormValues(currentParticipant?.fields)}
									loading={updatingParticipantFields === 'pointOfContact'}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={6}>
							<Stack spacing={2}>
								<CompanyInfoSection
									participantFields={currentParticipant?.fields ?? []}
									loading={updatingParticipantFields === 'companyInfo'}
								/>
								{isParticipantBussinesSide && (
									<PaymentDetailsSection
										payment_type={payment_type}
										iban={iban}
										bic={bic}
										country={country}
										loading={false}
									/>
								)}
							</Stack>
						</Grid>
					</Grid>
				)}
				{currentTab == 'finances' && (
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							{promptToOpenMerchantAccountAtStripe && (
								<Alert
									severity="info"
									action={
										<RdvLoadingTextButton
											loading={connectingToStripe}
											disabled={connectingToStripe}
											color="primary"
											size="small"
											sx={{ margin: 'auto' }}
											onClick={handleConnectToStripeConnect}
										>
											{intl.formatMessage({
												id: 'participantAccountPage.finances.merchantAccount.linkAccounts.linkBtn',
											})}
										</RdvLoadingTextButton>
									}
								>
									<AlertTitle>
										{intl.formatMessage({
											id: 'participantAccountPage.finances.merchantAccount.linkAccounts.bannerTitle',
										})}
									</AlertTitle>
									{intl.formatMessage({
										id: 'participantAccountPage.finances.merchantAccount.linkAccounts.bannerText',
									})}
								</Alert>
							)}
							{requiresFurtherStripeOnboarding && (
								<Alert
									severity="error"
									action={
										<RdvLoadingTextButton
											loading={connectingToStripe}
											disabled={connectingToStripe}
											color="primary"
											size="small"
											sx={{ margin: 'auto' }}
											onClick={handleConnectToStripeConnect}
										>
											{intl.formatMessage({
												id: 'participantAccountPage.finances.merchantAccount.continueLinkingAccounts.continueBtn',
											})}
										</RdvLoadingTextButton>
									}
								>
									<AlertTitle>
										{intl.formatMessage({
											id: 'participantAccountPage.finances.merchantAccount.continueLinkingAccounts.bannerTitle',
										})}
									</AlertTitle>
									{intl.formatMessage({
										id: 'participantAccountPage.finances.merchantAccount.continueLinkingAccounts.bannerText',
									})}
								</Alert>
							)}

							{merchantAccountAtStripeOpened && (
								<>
									<Typography variant="h4" sx={{ mb: 2 }}>
										{intl.formatMessage({
											id: 'participantAccountPage.finances.merchantAccount.linkedAccounts.title',
										})}
									</Typography>
									<Stack
										direction={{ xs: 'column', md: 'row' }}
										spacing={2}
										width="100%"
										justifyContent="space-between"
										alignItems="center"
									>
										<Stack direction="column" width="100%">
											<Typography variant="body1">Stripe</Typography>
											<Typography variant="body2" color="textSecondary">{`${
												stripeMerchantAccount?.data?.email_connected_account ?? ''
											} ${stripeMerchantAccount?.id_psp_account}`}</Typography>
											<Typography variant="body2" color="textSecondary">
												{stripeMerchantAccount?.data?.name_connected_account ?? ''}
											</Typography>
										</Stack>

										<Stack
											direction="row"
											width="100%"
											spacing={2}
											justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
										>
											<RdvLoadingButton
												color="primary"
												size="small"
												variant="text"
												loading={loadingRequestingMerchantAccountExternalUrl} //FIXME @Rokva- add loading
												disabled={loadingRequestingMerchantAccountExternalUrl} //FIXME @Rokva- add disabled
												onClick={() => handleGoToStripeDashboard()}
											>
												{intl.formatMessage({
													id: 'participantAccountPage.finances.merchantAccount.linkedAccounts.viewAccountBtn',
												})}
											</RdvLoadingButton>
											<RdvLoadingButton
												color="secondary"
												size="small"
												variant="text"
												loading={loadingRequestPayout} //FIXME @Rokva- add loading
												disabled={loadingRequestPayout} //FIXME @Rokva- add disabled
												onClick={() => handleRequestPayout()}
											>
												{intl.formatMessage({
													id: 'participantAccountPage.finances.merchantAccount.linkedAccounts.requestPayoutBtn',
												})}
											</RdvLoadingButton>
										</Stack>
									</Stack>
								</>
							)}
						</Grid>
						<Grid item xs={12}>
							<TransfersTable
								transfers={transfers}
								loading={loadingTransfers}
								disabled={loadingTransfers}
								hasNextPage={Boolean(transfersPageInfo?.has_next_page)}
								hasPreviousPage={Boolean(transfersPageInfo?.has_previous_page)}
								onOpenTransferDetailsDialog={handleOpenTransferDetailsDialog}
								onNextPage={handleNextTransfersPage}
								onPreviousPage={handlePreviosTransfersPage}
							/>
						</Grid>
					</Grid>
				)}
			</Stack>
		</>
	);
};
export default ParticipantAccountPage;
