import { print } from 'graphql';
import gql from 'graphql-tag';

const QUERY_ME = gql`
	query {
		me {
			id
			email
		}
	}
`;

const getCurrentUser = () => JSON.stringify({ query: print(QUERY_ME) });

const QUERY_MY_PARTICIPANTS = gql`
	query MY_PARTICIPANTS {
		myParticipants {
			id
			account {
				data
			}
			type {
				id
				tech_name
			}
			status
			account {
				merchant_accounts {
					id
					payment_provider_kind
					id_psp_account
					data
				}
			}
			id_onboarding_flow
			onboarding_progress {
				status
				status_tech_name
				message
				next_steps {
					type
					config
				}
			}
			fields {
				... on SimpleField {
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
						}
					}
				}

				... on ObjectSetField {
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
						}
					}
				}
			}
		}
	}
`;

const myParticipants = () => JSON.stringify({ query: print(QUERY_MY_PARTICIPANTS) });

export { getCurrentUser, myParticipants };
