import File from '@aquga/components/common/File';
import AnswerMatchStatus from '@aquga/components/questions/status/AnswerMatchStatus';
import { RdvFileFieldValue } from '@aquga/components/services/types';
import { Skeleton, Stack } from '@mui/material';
import React from 'react';

interface AnswerAcceptedViewProps {
	solutionDocuments: RdvFileFieldValue[];
	status: string;
	loading: boolean;
}

export const AnswerAcceptedView = ({ solutionDocuments, status, loading }: AnswerAcceptedViewProps) => {
	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
			<AnswerMatchStatus status={status} loading={loading} />
			{loading ? (
				<Skeleton width={120} />
			) : (
				solutionDocuments?.map((doc: any) => <File key={doc.id_file} url={doc.url} fileName={doc.file_name} />)
			)}
		</Stack>
	);
};
