import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import { mapTransactionDetails } from '@aquga/app/ticketHelpers';
import { AgreedDataView } from '@aquga/components/common/AgreedDataView';
import { AnswerAcceptedView } from '@aquga/components/common/AnswerAcceptedView';
import { AnswerDeliveredView } from '@aquga/components/common/AnswerDeliveredView';
import { ContractAcceptedView } from '@aquga/components/common/ConsultantContractAcceptedView';
import { ConsultantContractOfferedView } from '@aquga/components/common/ConsultantContractOfferedView';
import { ContractDeclinedView } from '@aquga/components/common/ContractDeclinedView';
import PageHeader from '@aquga/components/common/PageHeader';
import { RequestedChangesView } from '@aquga/components/common/RequestedChangesView';
import SideDrawer from '@aquga/components/common/SideDrawer';
import { UploadSolutionView } from '@aquga/components/common/UploadSolutionView';
import { MatchDetailsDrawerContent } from '@aquga/components/matches/MatchDetailsDrawerContent';
import ContactDetailsView from '@aquga/components/matches/provider/details/ContactDetailsView';
import { MeetingProposedView } from '@aquga/components/matches/provider/details/MeetingProposedView';
import { ProviderMeetingScheduledView } from '@aquga/components/matches/provider/details/ProviderMeetingScheduledView';
import { ProviderMatchOptions } from '@aquga/components/matches/provider/ProviderMatchOptions';
import QuestionMatches from '@aquga/components/questions/details/QuestionMatches';
import QuestionRequirements from '@aquga/components/questions/details/QuestionRequirements';
import QuestionTabs from '@aquga/components/questions/details/QuestionTabs';
import QuestionTitleDescriptionAdditionalNotesAndDocs from '@aquga/components/questions/details/QuestionTitleDescriptionAdditionalNotesAndDocs';
import ContractMatchStatus from '@aquga/components/questions/status/ContractMatchStatus';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { ROUTES } from '@aquga/config/routes';
import { CurrencyKind, PaymentMethodKind } from '@aquga/services/randevuApi/generatedTypes';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import {
	loadMyMatch,
	loadMyTicket,
	selectLoading,
	selectMatchLoading,
	selectMyMatch,
	selectMyTicket,
} from '@aquga/store/slices/transactionSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';

export interface PaymentMethodDetails {
	type: string;
	country: string;
	currency: CurrencyKind;
	financial_addresses: {
		iban: {
			bic: string;
			iban: string;
			country: string;
			account_holder_name: string;
		};
		type: PaymentMethodKind;
	}[];
}

const TicketDetailsPage = () => {
	const [selectedTab, setSelectedTab] = useState<'overview' | 'description' | 'matches'>('overview');
	const [selectedSubTransactionId, setSelectedSubTransactionId] = useState<string>('');
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	const { id } = useParams();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const requestTransaction = useAppSelector(selectMyTicket);
	const selectedMatchTransaction = useAppSelector(selectMyMatch);
	const loading = useAppSelector(selectLoading);
	const matchLoading = useAppSelector(selectMatchLoading);
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const [isRespondentDetailsExpanded, setRespondentDetailsExpanded] = useState(false);
	const [isAnswerDetailsExpanded, setAnswerDetailsExpanded] = useState(false);

	const intl = useIntl();
	const metaTitle = `${intl.formatMessage({ id: 'questions.details.metaTitle' })} - AQUGA`;

	const question = mapTransactionDetails(requestTransaction);
	const subtransaction = mapTransactionDetails(selectedMatchTransaction);

	const isBusiness = isBusinessSide(currentParticipant);
	const contractStates = [
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_DECLINED.TECH_NAME.toUpperCase(),
	];

	const answeringStates = [
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_TO_BE_IMPROVED.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_ACCEPTED.TECH_NAME.toUpperCase(),
	];

	useEffect(() => {
		if (contractStates?.includes(question?.status?.toUpperCase())) setRespondentDetailsExpanded(true);

		if (answeringStates?.includes(question?.status?.toUpperCase())) setAnswerDetailsExpanded(true);
	}, [question?.status]);

	useEffect(() => {
		if ([null, undefined, ''].includes(id)) {
			navigate(-1);
		}
	}, []);

	useEffect(() => {
		if (id && id?.length > 0) {
			dispatch(loadMyTicket({ id_ticket: id ?? '' }));
		}
	}, [id, dispatch]);

	useEffect(() => {
		if (selectedSubTransactionId && selectedSubTransactionId?.length > 0) {
			dispatch(loadMyMatch({ id_match: selectedSubTransactionId ?? '' }));
		}
	}, [selectedSubTransactionId, dispatch]);

	// usePollingEffect(
	// 	async () => dispatch(pollMyTicketMatchesAsBusinessAndTicketDetailsAsConsultant({ id_ticket: id ?? '' })),
	// 	[]
	// );

	// usePollingEffect(async () => {
	// 	if (selectedSubTransactionId && selectedSubTransactionId?.length > 0) {
	// 		dispatch(pollMyQuestionMatchDetails({ id_match: selectedSubTransactionId }));
	// 	}
	// }, [selectedSubTransactionId]);

	const handleSelectTab = (value: 'overview' | 'description' | 'matches') => {
		setSelectedTab(value);
	};
	const handleSeeMatchDetails = (matchId: string) => {
		setSelectedSubTransactionId(matchId);
		setOpenDrawer(true);
	};

	const consultingExpertiseTitle =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TITLE.TECH_NAME
		)?.value;
	const consultingExpertiseDescription =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.DESCRIPTION.TECH_NAME
		)?.value;

	const supplyTechnology =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.TECH_NAME
		)?.value;
	const supplyBusinessArea =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.TECH_NAME
		)?.value;
	const supplyTechnologyOther =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KIND_OTHER.TECH_NAME
		)?.value;
	const supplyBusinessAreaOther =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA_OTHER.TECH_NAME
		)?.value;
	const supplyAdditionalDocs =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
		)?.value;
	const supplyAdditionalNotes =
		selectedMatchTransaction &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.supply_fields,
			RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.ADDITIONAL_NOTES.TECH_NAME
		)?.value;

	const contractFromTransactionSignedByConsultant =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			requestTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT_SIGNED_BY_CONSULTANT
		)?.value;

	const contractFromTransaction =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			requestTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT
		)?.value;

	const contractFromSubTransaction =
		(selectedMatchTransaction?.matches &&
			findFieldByTechName(
				selectedMatchTransaction?.matches?.[0]?.fields,
				RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT_SIGNED_BY_CONSULTANT
			)?.value) ??
		(selectedMatchTransaction?.matches &&
			findFieldByTechName(
				selectedMatchTransaction?.matches?.[0]?.fields,
				RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT
			)?.value);

	const contactPerson =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			isBusiness
				? requestTransaction?.matches?.[0]?.provider_fields
				: requestTransaction?.matches?.[0]?.consumer_fields,
			isBusiness
				? RANDEVU_IDS.USER_TYPE_CUSTOMER.FIELDS.CONTACT_PERSON.TECH_NAME
				: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.CONTACT_PERSON.TECH_NAME
		)?.value;
	const contactEmail =
		requestTransaction &&
		requestTransaction.matches &&
		findFieldByTechName(
			isBusiness
				? requestTransaction?.matches?.[0]?.provider_fields
				: requestTransaction?.matches?.[0]?.consumer_fields,
			'contact_email'
		)?.value;
	const contactNumber =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			isBusiness
				? requestTransaction?.matches?.[0]?.provider_fields
				: requestTransaction?.matches?.[0]?.consumer_fields,
			isBusiness
				? RANDEVU_IDS.USER_TYPE_CUSTOMER.FIELDS.PHONE_NUMBER.TECH_NAME
				: RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.PHONE_NUMBER.TECH_NAME
		)?.value;
	const solutionFromTransaction =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			requestTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.SOLUTION_DOCUMENTS
		)?.value;

	const solutionFromMatch =
		selectedMatchTransaction &&
		selectedMatchTransaction?.matches &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.SOLUTION_DOCUMENTS
		)?.value;

	const requestedChangesFromTransaction =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			requestTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.SOLUTION_CHANGE_REQUEST_REASON
		)?.value;

	const requestedChangesFromMatch =
		selectedMatchTransaction &&
		selectedMatchTransaction?.matches &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.SOLUTION_CHANGE_REQUEST_REASON
		)?.value;

	const contractDeclinedReasonFromTransaction =
		requestTransaction &&
		requestTransaction?.matches &&
		findFieldByTechName(
			requestTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT_DECLINED_REASON
		)?.value;

	const contractDeclinedReasonFromMatch =
		selectedMatchTransaction &&
		selectedMatchTransaction?.matches &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.CONTRACT_DECLINED_REASON
		)?.value;

	const generatedContract =
		selectedMatchTransaction &&
		selectedMatchTransaction?.matches &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.fields,
			RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.GENERATED_CONTRACT
		)?.value;

	const providerOptionPossibleStateTypes = [
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.OPEN_OFFER.TECH_NAME.toUpperCase(),
		RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONSULTANT_APPLIED.TECH_NAME.toUpperCase(),
	];

	const matchProviderFirstName =
		selectedMatchTransaction &&
		selectedMatchTransaction?.matches &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.provider_fields,
			RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.FIRST_NAME.TECH_NAME
		)?.value;
	const matchProviderLastName =
		selectedMatchTransaction &&
		selectedMatchTransaction?.matches &&
		findFieldByTechName(
			selectedMatchTransaction?.matches?.[0]?.provider_fields,
			RANDEVU_IDS.USER_TYPE_CONSULTING_HOUSE.FIELDS.LAST_NAME.TECH_NAME
		)?.value;

	const totalBeforeTax = selectedMatchTransaction?.chargeable?.total_before_tax ?? 0;
	const totalTaxes = selectedMatchTransaction?.chargeable?.total_taxes ?? 0;
	const totalToPay = selectedMatchTransaction?.chargeable?.total_to_charge ?? 0;

	const agreedPrice = question?.agreedPrice;

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>

			<PageHeader title={intl.formatMessage({ id: 'questions.list.title' })} />
			<br />
			<Box sx={{ overflowX: 'hidden' }}>
				<Breadcrumbs>
					<Button
						component={Link}
						disableRipple
						disableFocusRipple
						disableElevation
						color="secondary"
						to={ROUTES.TICKETS_W_FILTER_ACTIVE}
						startIcon={<KeyboardBackspaceIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
					>
						{intl.formatMessage({ id: 'common.back' })}
					</Button>

					<Button
						disableRipple
						disableElevation
						disableFocusRipple
						color="inherit"
						to={ROUTES.TICKETS_W_FILTER_ACTIVE}
						component={Link}
					>
						{intl.formatMessage({ id: 'questions.create.breadcrumbs.questions' })}
					</Button>

					<Typography
						color="text.primary"
						variant="caption"
						fontWeight="500"
						noWrap
						display="block"
						sx={{ width: 400 }}
					>
						{question?.title}
					</Typography>
				</Breadcrumbs>
				<br />
				<SideDrawer
					title={
						matchProviderFirstName && matchProviderLastName
							? `${matchProviderFirstName} ${matchProviderLastName}`
							: ''
					}
					open={openDrawer}
					onClose={() => setOpenDrawer(false)}
				>
					<MatchDetailsDrawerContent
						contractDeclinedReason={contractDeclinedReasonFromMatch}
						requestedChangesFromMatch={requestedChangesFromMatch}
						solutionDocuments={solutionFromMatch}
						transactionId={subtransaction?.id || ''}
						ticketNumber={question.ticketNumber}
						rawMatchData={selectedMatchTransaction}
						contract={contractFromSubTransaction}
						generatedContract={generatedContract}
						match={subtransaction}
						contactEmail={contactEmail}
						loading={matchLoading}
						consultingExpertiseTitle={consultingExpertiseTitle}
						consultingExpertiseDescription={consultingExpertiseDescription}
						supplyTechnology={supplyTechnology}
						supplyBusinessArea={supplyBusinessArea}
						supplyTechnologyOther={supplyTechnologyOther}
						supplyBusinessAreaOther={supplyBusinessAreaOther}
						supplyAdditionalDocs={supplyAdditionalDocs}
						supplyAdditionalNotes={supplyAdditionalNotes}
						contactNumber={contactNumber}
						contactPerson={contactPerson}
						payment_metod_details={selectedMatchTransaction?.payments?.[0]?.payment_method_details}
						totalBeforeTax={totalBeforeTax}
						tax={totalTaxes}
						totalToPay={totalToPay}
					/>
				</SideDrawer>

				<Stack direction={{ xs: 'column', md: 'row' }} gap={2} sx={{ width: '100%' }}>
					<Box sx={{ borderRadius: 3, width: { xs: '100%', md: '60%' } }} component={Paper} elevation={0}>
						<QuestionTabs
							loading={loading}
							isBusiness={isBusiness}
							selected={selectedTab}
							matchesCount={question.subTransactions.length}
							onSelect={handleSelectTab}
						/>

						{['overview', 'description'].includes(selectedTab) && (
							<QuestionTitleDescriptionAdditionalNotesAndDocs
								title={question.title}
								loading={loading}
								description={question.description}
								ticketNumber={question.ticketNumber}
								additionalNote={question.additionalNote}
								additionalDocs={question.additionalDocs}
							/>
						)}
						{['overview'].includes(selectedTab) && (
							<Box pl={4} pr={4}>
								<Divider />
							</Box>
						)}
						{isBusiness ? (
							['overview', 'matches'].includes(selectedTab) && (
								<QuestionMatches
									loading={loading}
									subTransactions={question.subTransactions.filter(
										(st: any) =>
											st.status !==
											RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.OPEN_OFFER.TECH_NAME
									)}
									questionStatus={question.status}
									onSeeDetails={handleSeeMatchDetails}
								/>
							)
						) : (
							<></>
						)}
					</Box>

					<Stack
						sx={{ borderRadius: 3, width: { xs: '100%', md: '40%' } }}
						alignItems="flex-start"
						spacing={2}
					>
						{isBusiness ? (
							<></>
						) : (
							providerOptionPossibleStateTypes.includes(question?.status?.toUpperCase()) && (
								<Box
									sx={{ borderRadius: 3, width: { xs: '100%' }, p: 2 }}
									component={Paper}
									elevation={0}
								>
									<ProviderMatchOptions
										loading={loading}
										status={question.status}
										transactionId={question?.id}
									/>
								</Box>
							)
						)}
						{contractStates.includes(question?.status?.toUpperCase()) && (
							<Accordion
								expanded={isRespondentDetailsExpanded}
								onChange={() => setRespondentDetailsExpanded((expanded) => !expanded)}
								sx={{
									borderRadius: '12px',
									width: '100%',
									p: 2,
									'&:before': {
										display: 'none',
									},
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="subtitle2">
										<FormattedMessage id="questions.details.contract.title" />
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase() && (
										<ContractMatchStatus status={question.status} loading={loading} />
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TECH_NAME.toUpperCase() && (
										<ConsultantContractOfferedView
											loading={loading}
											status={question.status}
											doc={contractFromTransaction}
											transactionId={question.id || ''}
										/>
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase() && (
										<ContractAcceptedView
											loading={loading}
											status={question.status}
											contract={contractFromTransactionSignedByConsultant}
										/>
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase() && (
										<ContractAcceptedView
											loading={loading}
											status={question.status}
											contract={contractFromTransactionSignedByConsultant}
										/>
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_DECLINED.TECH_NAME.toUpperCase() && (
										<ContractDeclinedView
											loading={loading}
											status={question.status}
											contract={contractFromTransaction}
											contractDeclinedReason={contractDeclinedReasonFromTransaction}
										/>
									)}
								</AccordionDetails>
							</Accordion>
						)}
						{answeringStates.includes(question?.status?.toUpperCase()) && (
							<Accordion
								expanded={isAnswerDetailsExpanded}
								onChange={() => setAnswerDetailsExpanded((expanded) => !expanded)}
								sx={{
									borderRadius: '12px',
									width: '100%',
									p: 2,
									'&:before': {
										display: 'none',
									},
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="subtitle2">
										<FormattedMessage id="questions.details.answer.title" />
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_ACCEPTED.TECH_NAME.toUpperCase() && (
										<UploadSolutionView
											loading={loading}
											status={question.status}
											transactionId={question.id || ''}
										/>
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TECH_NAME.toUpperCase() && (
										<AnswerDeliveredView
											loading={loading}
											transactionId={question.id || ''}
											status={question.status}
											buttons={false}
											solutionDocuments={solutionFromTransaction}
										/>
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_TO_BE_IMPROVED.TECH_NAME.toUpperCase() && (
										<UploadSolutionView
											loading={loading}
											previousSolutionDocuments={solutionFromTransaction}
											status={question.status}
											requestedChanges={requestedChangesFromTransaction}
											transactionId={question.id || ''}
										/>
									)}
									{question?.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_ACCEPTED.TECH_NAME.toUpperCase() && (
										<AnswerAcceptedView
											loading={loading}
											status={question.status}
											solutionDocuments={solutionFromTransaction}
										/>
									)}
								</AccordionDetails>
							</Accordion>
						)}

						<Box sx={{ borderRadius: 3, width: { xs: '100%' }, p: 4 }} component={Paper} elevation={0}>
							<QuestionRequirements
								loading={loading}
								status={question.status}
								topics={question.topics}
								topicsOther={question.topicsOther}
								expertiseOther={question.expertiseOther}
								services={question.services}
								price={question.price}
								agreedPrice={agreedPrice}
								createdAt={question.createdAt}
								priority={question.priority}
								dueDate={question.dueDate}
								internalRefTicket={question.internal_ref_ticket}
								isBusiness={isBusiness}
							/>
							{isBusiness ? (
								<></>
							) : (
								<>
									{question.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.PROPOSED_TIME.TECH_NAME.toUpperCase() && (
										<MeetingProposedView
											loading={loading}
											transactionId={question?.id || ''}
											matchFields={requestTransaction?.matches?.[0]?.fields ?? []}
										/>
									)}
									{question.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.MEETING_INVITE.TECH_NAME.toUpperCase() && (
										<MeetingProposedView
											loading={loading}
											transactionId={question?.id || ''}
											matchFields={requestTransaction?.matches?.[0]?.fields ?? []}
											buttons={false}
										/>
									)}
									{question.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.SCHEDULED.TECH_NAME.toUpperCase() && (
										<>
											<MeetingProposedView
												loading={loading}
												transactionId={question?.id || ''}
												matchFields={requestTransaction?.matches?.[0].fields ?? []}
												buttons={false}
											/>
											<ProviderMeetingScheduledView
												oppositeSideParticipantName={question.consumerName}
												messages={question.messages}
												loading={loading}
												transactionId={question.id || ''}
												price={question.price}
												update_transition_tech_name={
													RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.SCHEDULED.TRANSITIONS
														.DEMAND_CHANGE_REQUIREMENTS
												}
												confirm_transition_tech_name={
													RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.SCHEDULED.TRANSITIONS
														.CONSULTANT_CONFIRMS_REQUIREMENTS
												}
											/>
										</>
									)}
									{question.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_RECEIVED.TECH_NAME.toUpperCase() && (
										<>
											<MeetingProposedView
												loading={loading}
												transactionId={question?.id || ''}
												matchFields={requestTransaction?.matches?.[0].fields ?? []}
												buttons={false}
											/>
											<RequestedChangesView
												loading={loading}
												matchFields={requestTransaction?.matches?.[0].fields}
											/>
											<AgreedDataView
												loading={loading}
												price={question?.price}
												matchFields={requestTransaction?.matches?.[0].fields}
											/>
											<ProviderMeetingScheduledView
												loading={loading}
												oppositeSideParticipantName={question.consumerName}
												messages={question.messages}
												transactionId={question.id || ''}
												update_transition_tech_name={
													RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_RECEIVED
														.TRANSITIONS.CHANGE_REQUEST_REJECTED
												}
												confirm_transition_tech_name={
													RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_RECEIVED
														.TRANSITIONS.CONSULTANT_CONFIRMS_REQUIREMENTS
												}
											/>
										</>
									)}
									{question.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_REQUESTED.TECH_NAME.toUpperCase() && (
										<>
											<MeetingProposedView
												loading={loading}
												transactionId={question?.id || ''}
												matchFields={requestTransaction?.matches?.[0].fields ?? []}
												buttons={false}
											/>
											<RequestedChangesView
												loading={loading}
												matchFields={requestTransaction?.matches?.[0].fields}
											/>
										</>
									)}
									{question.status?.toUpperCase() ===
										RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TECH_NAME.toUpperCase() && (
										<>
											<MeetingProposedView
												loading={loading}
												transactionId={question?.id || ''}
												matchFields={requestTransaction?.matches?.[0].fields ?? []}
												buttons={false}
											/>
											<RequestedChangesView
												loading={loading}
												matchFields={requestTransaction?.matches?.[0].fields}
											/>
											<AgreedDataView
												loading={loading}
												price={question.price}
												matchFields={requestTransaction?.matches?.[0].fields}
											/>
										</>
									)}
									{!isBusiness && contactNumber && contactPerson && (
										<ContactDetailsView
											contactPerson={contactPerson}
											contactNumber={contactNumber}
											contactEmail={contactEmail}
											loading={loading}
										/>
									)}
								</>
							)}
						</Box>
					</Stack>
				</Stack>
			</Box>
		</>
	);
};
export default TicketDetailsPage;
