import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useIntl, FormattedMessage } from 'react-intl';

interface PaymentDetailsSectionProps {
	payment_type: string;
	iban: string;
	bic: string;
	country: string;
	loading: boolean;
}

const PaymentDetailsSection = ({ payment_type, iban, bic, country, loading }: PaymentDetailsSectionProps) => {
	const intl = useIntl();

	return (
		<Accordion
			sx={{
				borderRadius: '12px !important',
				boxShadow: 'none',
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="subtitle2">
					<FormattedMessage id="participantAccount.paymentDetailsSection.title" />
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
					<Grid item xs={12} md={6}>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.type.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.iban.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.bic.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.country.title',
							})}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography>
							{payment_type
								? intl.formatMessage({
									id: 'participantAccount.paymentDetailsSection.vbanDetails.sepa.title',
								  })
								: '-'}
						</Typography>
						<Typography>{iban ?? '-'}</Typography>
						<Typography>{bic ?? '-'}</Typography>
						<Typography>{country ?? '-'}</Typography>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export default PaymentDetailsSection;
