import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import randevu from '@aquga/services/randevuApi';
import { isSessionExpired } from '@aquga/services/randevuApi/errors/errorHelper';
import { FieldInput, Transaction, TransactionLight, Transfer } from '@aquga/services/randevuApi/generatedTypes';
import { sleep } from '@aquga/services/sleep';
import { AppDispatch, RootState } from '@aquga/store/configureStore';
import { SHORT_SLEEP_LOADING_SPINNER } from '@aquga/store/lib';
import { requestReauthentication } from '@aquga/store/slices/authSlice';
import THUNKS from '@aquga/store/thunkKeys';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TransactionSliceState {
	loading: boolean;
	matchLoading: boolean;
	applyForTicketLoading: boolean;
	withdrawFromTicketLoading: boolean;
	declineTicketLoading: boolean;
	items: TransactionLight[] | null;
	item: Transaction | null;
	match: any | null;
	error: any | undefined;
}

// Initial state for the slice
const initialState: TransactionSliceState = {
	loading: false,
	matchLoading: false,
	applyForTicketLoading: false,
	declineTicketLoading: false,
	withdrawFromTicketLoading: false,
	items: [],
	item: null,
	match: null,
	error: undefined,
};

// Actual Slice
export const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		createTicketRequested: (state: TransactionSliceState) => {
			state.loading = true;
			state.error = undefined;
		},
		createTicketFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		ticketCreated: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.item = action?.payload;
		},
		loadMyTicketsRequested: (state: TransactionSliceState) => {
			state.loading = true;
			state.error = undefined;
		},
		loadMyTicketsFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		myTicketsLoaded: (state: TransactionSliceState, action: PayloadAction<TransactionLight[]>) => {
			state.loading = false;
			state.items = action.payload;
			state.error = undefined;
		},
		loadMyTicketRequested: (state: TransactionSliceState) => {
			state.loading = true;
			state.error = undefined;
		},
		loadMyTicketFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		myTicketLoaded: (state: TransactionSliceState, action: PayloadAction<Transaction>) => {
			state.loading = false;
			state.item = action.payload;
			state.error = undefined;
		},
		loadMyMatchRequested: (state: TransactionSliceState) => {
			state.matchLoading = true;
			state.error = undefined;
			state.match = undefined;
		},
		loadMyMatchFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.matchLoading = false;
			state.error = action?.payload;
		},
		myMatchLoaded: (state: TransactionSliceState, action: PayloadAction<any>) => {
			state.matchLoading = false;
			const updatedItem =
				state.item?.sub_transactions.map((field) => {
					if (field.id === action.payload.id) field.state = action.payload.state;
					return field;
				}) || null;
			if (state.item && state.item.sub_transactions)
				state.item.sub_transactions = updatedItem as TransactionLight[];
			state.match = action.payload;
			state.error = undefined;
		},
		triggerManualTransactionTransitionRequested: (state: TransactionSliceState) => {
			state.loading = true;
		},
		triggerManualTransactionTransitionFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		manualTransactionTransitionTriggered: (state: TransactionSliceState) => {
			state.loading = false;
		},
		withdrawProviderFromTicketRequested: (state: TransactionSliceState) => {
			state.withdrawFromTicketLoading = true;
		},
		withdrawProviderFromTicketFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.withdrawFromTicketLoading = false;
			state.error = action?.payload;
		},
		providerWithdrawnFromTicket: (state: TransactionSliceState) => {
			state.withdrawFromTicketLoading = false;
		},
		applyForTicketProviderRequested: (state: TransactionSliceState) => {
			state.applyForTicketLoading = true;
		},
		applyForTicketProviderFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.applyForTicketLoading = false;
			state.error = action?.payload;
		},
		providerAppliedForTicket: (state: TransactionSliceState) => {
			state.applyForTicketLoading = false;
		},
		triggerManualMatchTransitionRequested: (state: TransactionSliceState) => {
			state.matchLoading = true;
		},
		triggerManualMatchTransitionFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.matchLoading = false;
			state.error = action?.payload;
		},
		manualMatchTransitionTriggered: (state: TransactionSliceState) => {
			state.matchLoading = false;
		},
		acceptMatchRequested: (state: TransactionSliceState) => {
			state.matchLoading = true;
		},
		acceptMatchFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.matchLoading = false;
			state.error = action?.payload;
		},
		matchAccepted: (state: TransactionSliceState) => {
			state.matchLoading = false;
		},
		declineMatchRequested: (state: TransactionSliceState) => {
			state.matchLoading = true;
		},
		declineMatchFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.matchLoading = false;
			state.error = action?.payload;
		},
		matchDeclined: (state: TransactionSliceState) => {
			state.matchLoading = false;
		},
		acceptTransactionRequested: (state: TransactionSliceState) => {
			state.loading = true;
		},
		acceptTransactionFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		transactionAccepted: (state: TransactionSliceState) => {
			state.loading = false;
		},
		declineTransactionRequested: (state: TransactionSliceState) => {
			state.declineTicketLoading = true;
		},
		declineTransactionFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.declineTicketLoading = false;
			state.error = action?.payload;
		},
		transactionDeclined: (state: TransactionSliceState) => {
			state.declineTicketLoading = false;
		},
		updateMatchRequested: (state: TransactionSliceState) => {
			state.matchLoading = true;
		},
		updateMatchFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.matchLoading = false;
			state.error = action?.payload;
		},
		matchUpdated: (state: TransactionSliceState) => {
			state.matchLoading = false;
		},
		updateTicketRequested: (state: TransactionSliceState) => {
			state.loading = true;
		},
		updateTicketFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.loading = false;
			state.error = action?.payload;
		},
		ticketUpdated: (state: TransactionSliceState) => {
			state.loading = false;
		},
		pollMyTicketRequested: (state: TransactionSliceState) => {
			state.error = undefined;
		},
		pollMyTicketFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.error = action?.payload;
		},
		myTicketPolled: (state: TransactionSliceState, action: PayloadAction<Transaction>) => {
			state.item = action.payload;
			state.error = undefined;
		},
		pollMyMatchRequested: (state: TransactionSliceState) => {
			state.error = undefined;
		},
		pollMyMatchFailed: (state: TransactionSliceState, action?: PayloadAction<any>) => {
			state.error = action?.payload;
		},
		myMatchPolled: (state: TransactionSliceState, action: PayloadAction<any>) => {
			state.item?.sub_transactions.map((field) => {
				if (field.id === action.payload.id) return action.payload;
				return field;
			});
			state.match = action.payload;
			state.error = undefined;
		},
	},
});

export const {
	createTicketRequested,
	createTicketFailed,
	ticketCreated,
	loadMyTicketsRequested,
	loadMyTicketsFailed,
	myTicketsLoaded,
	loadMyTicketRequested,
	loadMyTicketFailed,
	myTicketLoaded,
	loadMyMatchRequested,
	loadMyMatchFailed,
	myMatchLoaded,
	triggerManualTransactionTransitionRequested,
	triggerManualTransactionTransitionFailed,
	manualTransactionTransitionTriggered,
	acceptMatchRequested,
	acceptMatchFailed,
	matchAccepted,
	declineMatchRequested,
	declineMatchFailed,
	matchDeclined,
	triggerManualMatchTransitionRequested,
	triggerManualMatchTransitionFailed,
	manualMatchTransitionTriggered,
	acceptTransactionRequested,
	acceptTransactionFailed,
	transactionAccepted,
	declineTransactionRequested,
	declineTransactionFailed,
	transactionDeclined,
	updateMatchRequested,
	updateMatchFailed,
	matchUpdated,
	updateTicketRequested,
	updateTicketFailed,
	ticketUpdated,
	pollMyTicketRequested,
	pollMyTicketFailed,
	myTicketPolled,
	pollMyMatchRequested,
	pollMyMatchFailed,
	myMatchPolled,
	applyForTicketProviderRequested,
	applyForTicketProviderFailed,
	providerAppliedForTicket,
	withdrawProviderFromTicketRequested,
	withdrawProviderFromTicketFailed,
	providerWithdrawnFromTicket,
} = transactionSlice.actions;

interface CreateTicketRequest {
	supply_filter: any[];
	fields: FieldInput[];
	field_additional_docs?: { tech_name: string; value: File[] };
}
export const createTicket =
	({ fields, supply_filter, field_additional_docs }: CreateTicketRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(createTicketRequested());

			await sleep(SHORT_SLEEP_LOADING_SPINNER);

			const randevuService = new randevu({ token: getState().auth.token });

			const input_field_additional_docs_w_uploaded_files = {
				tech_name: field_additional_docs?.tech_name ?? '',
				value: [] as any[],
			};

			// Upload additional docs if any provided
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore:  Object is possibly 'undefined'.ts(2532)
			if (field_additional_docs?.value?.length > 0) {
				input_field_additional_docs_w_uploaded_files.value = await randevuService.files.uploadFiles({
					files: field_additional_docs?.value,
				});

				if (input_field_additional_docs_w_uploaded_files.value.length > 0) {
					fields.push(input_field_additional_docs_w_uploaded_files);
				}
			}

			const matching_order_request = {
				transaction_tech_name: RANDEVU_IDS.TRANSACTION_TYPES.TECH_NAME_CREATE_TICKET,
				supply_filter: supply_filter,
				consumer_filter: [],
				provider_filter: [],
				fields: fields,
			};

			const { id_transaction, errors } =
			await randevuService.transactions.prepareAndInitiateReverseAuctionTransaction(matching_order_request);

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.CREATE_TICKET.key,
						payload: { fields, supply_filter, field_additional_docs },
					})
				);
				return dispatch(createTicketFailed(undefined));
			}

			dispatch(ticketCreated(id_transaction));
			return dispatch(loadMyTicket({ id_ticket: id_transaction }));
		};

export const loadMyTickets = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadMyTicketsRequested());

	await sleep(SHORT_SLEEP_LOADING_SPINNER);
	const randevuService = new randevu({ token: getState().auth.token });

	const { transactions, errors } = await randevuService.transactions.myTransactions();

	transactions?.sort((x: any, y: any) => new Date(y?.created_at).getTime() - new Date(x?.created_at).getTime());

	if (isSessionExpired(errors)) {
		dispatch(
			requestReauthentication({
				callbackThunkKey: THUNKS.LOAD_MY_TICKETS.key,
			})
		);
		return dispatch(loadMyTicketsFailed(errors));
	}

	return dispatch(myTicketsLoaded(transactions));
};
interface LoadMyTicketRequest {
	id_ticket: string;
}
export const loadMyTicket =
	({ id_ticket }: LoadMyTicketRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(loadMyTicketRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transaction, errors } = await randevuService.transactions.myTransaction({ id: id_ticket });

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.LOAD_MY_TICKET.key,
						payload: { id_ticket },
					})
				);
				return dispatch(loadMyTicketFailed(errors));
			}

			return dispatch(myTicketLoaded(transaction));
		};

export const pollMyTicketMatchesAsBusinessAndTicketDetailsAsConsultant =
	({ id_ticket }: LoadMyTicketRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(pollMyTicketRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const { transaction, errors } = await randevuService.transactions.myTransaction({ id: id_ticket });

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.POLL_DETAILS.key,
						payload: { id_ticket },
					})
				);
				return dispatch(pollMyTicketFailed(errors));
			}

			return dispatch(myTicketPolled(transaction));
		};

export const pollMyQuestionMatchDetails =
	({ id_match }: LoadMyMatchRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(pollMyMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const { transaction, errors } = await randevuService.transactions.myTransaction({ id: id_match });

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.POLL_MATCH_DETAILS.key,
						payload: { id_match },
					})
				);
				return dispatch(pollMyMatchFailed(errors));
			}

			return dispatch(myMatchPolled(transaction));
		};

interface LoadMyMatchRequest {
	id_match: string;
}

export const loadMyMatch =
	({ id_match }: LoadMyMatchRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(loadMyMatchRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transaction, errors } = await randevuService.transactions.myTransaction({ id: id_match });

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.LOAD_MY_MATCH.key,
						payload: { id_match },
					})
				);
				return dispatch(loadMyMatchFailed(errors));
			}

			return dispatch(myMatchLoaded(transaction));
		};

interface TriggerManualMatchTransitionRequest {
	id_transaction: string;
	transition_tech_name: string;
}

export const triggerManualMatchTransition =
	({ id_transaction, transition_tech_name }: TriggerManualMatchTransitionRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(triggerManualMatchTransitionRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transition_triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: id_transaction,
				transition_tech_name,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.TRIGGER_MANUAL_MATCH_TRANSITION.key,
						payload: { id_transaction, transition_tech_name },
					})
				);
				return dispatch(triggerManualMatchTransitionFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myMatchLoaded(transaction));

			return dispatch(manualMatchTransitionTriggered());
		};

interface TriggerManualTransactionTransitionRequest {
	id_transaction: string;
	transition_tech_name: string;
}

export const triggerManualTransactionTransition =
	({ id_transaction, transition_tech_name }: TriggerManualTransactionTransitionRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(triggerManualTransactionTransitionRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transition_triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: id_transaction,
				transition_tech_name,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.TRIGGER_MANUAL_TRANSACTION_TRANSITION.key,
						payload: { id_transaction, transition_tech_name },
					})
				);
				return dispatch(triggerManualTransactionTransitionFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myTicketLoaded(transaction));

			return dispatch(manualTransactionTransitionTriggered());
		};
interface WithdrawFromTicketProvider {
	id_transaction: string;
}

export const withdrawFromTicketProvider =
	({ id_transaction }: WithdrawFromTicketProvider) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(withdrawProviderFromTicketRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transition_triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: id_transaction,
				transition_tech_name: 'consultant_withdraws_application',
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.WITHDRAW_FROM_TICKET_PROVIDER.key,
						payload: { id_transaction },
					})
				);
				return dispatch(withdrawProviderFromTicketFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myTicketLoaded(transaction));

			return dispatch(providerWithdrawnFromTicket());
		};
interface applyForTicketProvider {
	id_transaction: string;
}

export const applyForTicketProvider =
	({ id_transaction }: applyForTicketProvider) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(applyForTicketProviderRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transition_triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: id_transaction,
				transition_tech_name: 'consultant_applies_for_ticket',
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.APPLY_FOR_TICKET_PROVIDER.key,
						payload: { id_transaction },
					})
				);
				return dispatch(applyForTicketProviderFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myTicketLoaded(transaction));

			return dispatch(providerAppliedForTicket());
		};

interface TriggerManualTransactionTransitionRequest {
	id_transaction: string;
	transition_tech_name: string;
}

export const triggerConsumerManualTransactionTransition =
	({ id_transaction, transition_tech_name }: TriggerManualTransactionTransitionRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(triggerManualMatchTransitionRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transition_triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: id_transaction,
				transition_tech_name,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.TRIGGER_CONSUMER_MANUAL_TRANSACTION_TRANSITION.key,
						payload: { id_transaction, transition_tech_name },
					})
				);
				return dispatch(triggerManualMatchTransitionFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myMatchLoaded(transaction));

			return dispatch(manualMatchTransitionTriggered());
		};

interface AcceptTransactedMatchRequest {
	id_transaction: string;
}

export const acceptTransactedMatch =
	({ id_transaction }: AcceptTransactedMatchRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(acceptMatchRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { accepted, errors } = await randevuService.transactions.acceptTransactedMatch({
				id_transaction,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.ACCEPT_MATCH.key,
						payload: { id_transaction },
					})
				);
				return dispatch(acceptMatchFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myMatchLoaded(transaction));

			return dispatch(matchAccepted());
		};

interface DeclineTransactedMatchRequest {
	id_transaction: string;
}

export const declineTransactedMatch =
	({ id_transaction }: DeclineTransactedMatchRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(declineMatchRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { declined, errors } = await randevuService.transactions.declineTransactedMatch({
				id_transaction,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.DECLINE_MATCH.key,
						payload: { id_transaction },
					})
				);
				return dispatch(declineMatchFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myMatchLoaded(transaction));

			return dispatch(matchDeclined());
		};

interface AcceptTransactedTicketRequest {
	id_transaction: string;
}

export const acceptTransactedTicket =
	({ id_transaction }: AcceptTransactedTicketRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(acceptTransactionRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { accepted, errors } = await randevuService.transactions.acceptTransactedMatch({
				id_transaction,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.ACCEPT_TRANSACTED_TICKET.key,
						payload: { id_transaction },
					})
				);
				return dispatch(acceptTransactionFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myTicketLoaded(transaction));

			return dispatch(transactionAccepted());
		};

interface DeclineTransactedTicketRequest {
	id_transaction: string;
}

export const declineTransactedTicket =
	({ id_transaction }: DeclineTransactedTicketRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(declineTransactionRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { declined, errors } = await randevuService.transactions.declineTransactedMatch({
				id_transaction,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.DECLINE_TRANSACTED_TICKET.key,
						payload: { id_transaction },
					})
				);
				return dispatch(declineTransactionFailed(undefined));
			}

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myTicketLoaded(transaction));

			return dispatch(transactionDeclined());
		};

interface UpdateTransactedMatchRequest {
	fields: FieldInput[];
	transactionId: string;
}

export const updateTransactedMatch =
	({ fields, transactionId }: UpdateTransactedMatchRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.UPDATE_TRANSACTED_MATCH.key,
						payload: { fields, transactionId },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			await sleep(2000);

			dispatch(loadMyMatch({ id_match: transactionId }));
			return dispatch(matchUpdated());
		};

interface UpdateRequirementsAndRejectTicketRequest {
	fields: FieldInput[];
	transactionId: string;
	transitionTechName: string;
}

export const updateRequirementsAndRejectTicket =
	({ fields, transactionId, transitionTechName }: UpdateRequirementsAndRejectTicketRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.UPDATE_REQUIREMENTS_AND_REJECT_TICKET.key,
						payload: { fields, transactionId, transitionTechName },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name: transitionTechName,
			});

			await sleep(2000);

			dispatch(loadMyTicket({ id_ticket: transactionId }));
			return dispatch(matchUpdated());
		};

interface UpdateRequirementsAndTransitionToChangesReceivedRequest {
	fields: FieldInput[];
	transactionId: string;
}

export const updateRequirementsAndTransitionToChangesReceived =
	({ fields, transactionId }: UpdateRequirementsAndTransitionToChangesReceivedRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.UPDATE_REQUIREMENTS_AND_TRANSITION_TO_CHANGES_RECIEVED.key,
						payload: { fields, transactionId },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name:
					RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CHANGES_REQUESTED.TRANSITIONS
						.CUSTOMER_UPDATED_REQUIREMENTS,
			});

			await sleep(2000);

			dispatch(loadMyMatch({ id_match: transactionId }));
			return dispatch(matchUpdated());
		};

interface UpdateScheduledMeetingTimeAndTriggerTransitionRequest {
	fields: FieldInput[];
	transactionId: string;
	transition_tech_name: string;
	isBusiness: boolean;
}

export const updateScheduledMeetingTimeAndTriggerTransition =
	({
		fields,
		transactionId,
		transition_tech_name,
		isBusiness,
	}: UpdateScheduledMeetingTimeAndTriggerTransitionRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const handleRequestChanges = () => {
				if (isBusiness) {
					dispatch(loadMyMatch({ id_match: transactionId }));
				} else {
					dispatch(loadMyTicket({ id_ticket: transactionId }));
				}
			};
			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.UPDATE_SCHEDULED_MEETING_TIME_AND_TRIGGER_TRANSITION.key,
						payload: { fields, transactionId, transition_tech_name },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name: transition_tech_name,
			});

			await sleep(2000);

			handleRequestChanges();
			return dispatch(matchUpdated());
		};

interface ConfirmRequirementsAndSetAgreedPriceRequest {
	id_transaction: string;
	transition_tech_name: string;
	fields: FieldInput[];
}

export const confirmRequirementsAndSetAgreedPrice =
	({ id_transaction, transition_tech_name, fields }: ConfirmRequirementsAndSetAgreedPriceRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(triggerManualTransactionTransitionRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: id_transaction,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.CONFIRM_REQUIREMENTS_AND_SET_AGREED_PRICE.key,
						payload: { id_transaction, transition_tech_name, fields },
					})
				);
				return dispatch(triggerManualTransactionTransitionFailed(undefined));
			}

			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: id_transaction,
				transition_tech_name,
			});

			// Wait before refetching transaction
			await sleep(2000);

			const { transaction } = await randevuService.transactions.myTransaction({ id: id_transaction });

			dispatch(myTicketLoaded(transaction));

			return dispatch(manualTransactionTransitionTriggered());
		};

interface offerContractRequest {
	contract_field: FieldInput;
	transactionId: string;
}

export const offerContract =
	({ contract_field, transactionId }: offerContractRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });
			const fields = [];
			const input_field_additional_docs_w_uploaded_files = {
				tech_name: contract_field?.tech_name ?? '',
				value: undefined as unknown,
			};

			// Upload additional docs if any provided
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore:  Object is possibly 'undefined'.ts(2532)
			if (contract_field?.value?.length > 0) {
				const files = await randevuService.files.uploadFiles({
					files: contract_field?.value,
				});
				input_field_additional_docs_w_uploaded_files.value = files[0];
				if (input_field_additional_docs_w_uploaded_files.value) {
					fields.push(input_field_additional_docs_w_uploaded_files);
				}
			}

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.OFFER_CONTRACT.key,
						payload: { contract_field, transactionId },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name:
					RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERING.TRANSITIONS.OFFER_CONTRACT,
			});

			await sleep(2000);

			dispatch(loadMyMatch({ id_match: transactionId }));
			return dispatch(matchUpdated());
		};

interface ConsultantAcceptContractRequest {
	contract_field: FieldInput;
	transactionId: string;
}

export const acceptContract =
	({ contract_field, transactionId }: offerContractRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateTicketRequested());
			const randevuService = new randevu({ token: getState().auth.token });
			const fields = [];
			const input_field_additional_docs_w_uploaded_files = {
				tech_name: contract_field?.tech_name ?? '',
				value: undefined as unknown,
			};

			// Upload additional docs if any provided
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore:  Object is possibly 'undefined'.ts(2532)
			if (contract_field?.value?.length > 0) {
				const files = await randevuService.files.uploadFiles({
					files: contract_field?.value,
				});
				input_field_additional_docs_w_uploaded_files.value = files[0];
				if (input_field_additional_docs_w_uploaded_files.value) {
					fields.push(input_field_additional_docs_w_uploaded_files);
				}
			}

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.ACCEPT_CONTRACT.key,
						payload: { contract_field, transactionId },
					})
				);
				return dispatch(updateTicketFailed(undefined));
			}
			if (errors) {
				return dispatch(updateTicketFailed(errors));
			}

			const { accepted, errors: acceptErrors } = await randevuService.transactions.acceptTransactedMatch({
				id_transaction: transactionId,
			});

			await sleep(2000);

			dispatch(loadMyTicket({ id_ticket: transactionId }));
			return dispatch(ticketUpdated());
		};

interface PresentSolutionRequest {
	additionalDocs: any[];
	solution_field: FieldInput;
	transitionTechName: string;
	transactionId: string;
}

export const presentSolution =
	({ additionalDocs, solution_field, transactionId, transitionTechName }: PresentSolutionRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateTicketRequested());
			const randevuService = new randevu({ token: getState().auth.token });
			const fields = [];
			const input_field_additional_docs_w_uploaded_files = {
				tech_name: solution_field?.tech_name ?? '',
				value: [] as any[],
			};

			// Upload additional docs if any provided
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore:  Object is possibly 'undefined'.ts(2532)
			if (solution_field?.value?.length > 0) {
				input_field_additional_docs_w_uploaded_files.value = await randevuService.files.uploadFiles({
					files: solution_field?.value,
				});
				input_field_additional_docs_w_uploaded_files.value.push(...additionalDocs);
				if (input_field_additional_docs_w_uploaded_files.value?.length > 0) {
					fields.push(input_field_additional_docs_w_uploaded_files);
				}
			}

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.PRESENT_SOLUTION.key,
						payload: { solution_field, transactionId, transitionTechName },
					})
				);
				return dispatch(updateTicketFailed(undefined));
			}
			if (errors) {
				return dispatch(updateTicketFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name: transitionTechName,
			});

			await sleep(2000);

			dispatch(loadMyTicket({ id_ticket: transactionId }));
			return dispatch(ticketUpdated());
		};

interface declineContractRequest {
	fields: FieldInput[];
	transactionId: string;
}

export const declineContract =
	({ fields, transactionId }: declineContractRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.DECLINE_CONTRACT.key,
						payload: { fields, transactionId },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name:
					RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.CONTRACT_OFFERED.TRANSITIONS.CONSULTANT_CANCELS_DEAL,
			});

			await sleep(2000);

			dispatch(loadMyTicket({ id_ticket: transactionId }));
			return dispatch(matchUpdated());
		};

interface RequestChangesRequest {
	fields: FieldInput[];
	transactionId: string;
	isBusiness: boolean;
}

export const requestChanges =
	({ fields, transactionId, isBusiness }: RequestChangesRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateMatchRequested());
			const randevuService = new randevu({ token: getState().auth.token });
			const handleRequestChanges = () => {
				if (isBusiness) {
					dispatch(loadMyMatch({ id_match: transactionId }));
				} else {
					dispatch(loadMyTicket({ id_ticket: transactionId }));
				}
			};

			const { match_updated, errors } = await randevuService.transactions.updateTransactedMatch({
				fields: fields,
				id_transaction: transactionId,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.REQUEST_CHANGES.key,
						payload: { fields, transactionId },
					})
				);
				return dispatch(updateMatchFailed(undefined));
			}
			if (errors) {
				return dispatch(updateMatchFailed(errors));
			}

			const { transition_triggered, errors: triggerErrors } =
			await randevuService.transactions.triggerManualTransactionTransition({
				id_transaction: transactionId,
				transition_tech_name:
					RANDEVU_IDS.TICKET_TRANSACTION.STATE_TYPES.ANSWER_DELIVERED.TRANSITIONS.CUSTOMER_REQUESTS_CHANGE,
			});

			await sleep(2000);

			handleRequestChanges();
			return dispatch(matchUpdated());
		};

export const selectMyTickets = (state: RootState) => state.transaction.items;
export const selectMyTicket = (state: RootState) => state.transaction.item;
export const selectMyMatch = (state: RootState) => state.transaction.match;
export const selectLoading = (state: RootState) => state.transaction.loading;
export const selectMatchLoading = (state: RootState) => state.transaction.matchLoading;
export const selectProviderApplyForTicketLoading = (state: RootState) => state.transaction.applyForTicketLoading;
export const selectProviderWithdrawFromTicketLoading = (state: RootState) =>
	state.transaction.withdrawFromTicketLoading;
export const selectProviderDeclineTicketLoading = (state: RootState) => state.transaction.declineTicketLoading;

export default transactionSlice.reducer;
