import { CostItemKind, Transfer } from '@aquga/services/randevuApi/generatedTypes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';

interface TransferDialogProps {
	transfer: Transfer;
	open: boolean;
	onClose: () => any;
}

const TransferDialog = ({ transfer, open, onClose }: TransferDialogProps) => {
	const currentLocale = useAppSelector(selectCurrentLanguage);
	const intl = useIntl();

	const providerTax =
		transfer?.order?.order_items?.find((item) => item.type === CostItemKind.ProviderTax)?.value ?? 0;

	const formattedGrossValue = Intl.NumberFormat(currentLocale, { style: 'currency', currency: 'EUR' }).format(
		transfer?.order?.gross_value / 100
	);
	const aqugaTakerate = Intl.NumberFormat(currentLocale, { style: 'currency', currency: 'EUR' }).format(
		(transfer?.order?.gross_value / 100) * 0.21
	);
	const formattedNetValue = Intl.NumberFormat(currentLocale, { style: 'currency', currency: 'EUR' }).format(
		transfer?.order?.net_value / 100
	);
	const taxes = Intl.NumberFormat(currentLocale, { style: 'currency', currency: 'EUR' }).format(providerTax / 100);

	return (
		<>
			<Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width="100%">
						<FormattedMessage id={'participantAccountPage.finances.transfer.detailsDialog.title'} />
						<IconButton color="inherit" onClick={onClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Grid container width="100%">
						<Grid item xs={6}>
							<Stack spacing={2}>
								<Typography>
									{' '}
									{intl.formatMessage({
										id: 'participantAccountPage.finances.transfer.detailsDialog.gross',
									})}
								</Typography>
								<Divider />
								<Typography>
									{' '}
									{intl.formatMessage({
										id: 'participantAccountPage.finances.transfer.detailsDialog.takerate',
									})}
								</Typography>
								<Typography>
									{' '}
									{intl.formatMessage({
										id: 'participantAccountPage.finances.transfer.detailsDialog.taxes',
									})}
								</Typography>
								<Divider />
								<Typography fontWeight="bold">
									{' '}
									{intl.formatMessage({
										id: 'participantAccountPage.finances.transfer.detailsDialog.earning',
									})}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack spacing={2}>
								<Typography>{formattedGrossValue}</Typography>
								<Divider />
								<Typography>{aqugaTakerate}</Typography>
								<Typography>{taxes}</Typography>
								<Divider />
								<Typography fontWeight="bold">{formattedNetValue}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default TransferDialog;
