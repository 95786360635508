import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { ROUTES } from '@aquga/config/routes';
import randevu from '@aquga/services/randevuApi';
import { isSessionExpired } from '@aquga/services/randevuApi/errors/errorHelper';
import {
	CurrencyKind,
	MutationPrepareTransactionPaymentArgs,
	MutationRequestPayoutArgs,
	MutationRequestStripeDashboardSignInLinkArgs,
	PageInfo,
	Payment,
	QueryMyTransfersArgs,
	Transfer,
	TransferConnection,
} from '@aquga/services/randevuApi/generatedTypes';
import { AppDispatch, RootState } from '@aquga/store/configureStore';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import THUNKS from '../thunkKeys';

import { requestReauthentication } from './authSlice';
import { myMatchLoaded } from './transactionSlice';

interface PaymentSliceState {
	loading: boolean;
	loadingRequestPayout: boolean;
	errors: any | null;
	transfers: Transfer[];
	requestingMerchantAccountExternalUrl: boolean;
	loadingConnectToStripeAccount: boolean;
	transfersPageInfo: PageInfo | null;
	preparedPayment: Payment | null;
}

const initialState: PaymentSliceState = {
	loading: false,
	loadingRequestPayout: false,
	requestingMerchantAccountExternalUrl: false,
	errors: null,
	transfers: [],
	loadingConnectToStripeAccount: false,
	transfersPageInfo: null,
	preparedPayment: null,
};

const slice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		loadMyTransfersRequested: (payment: PaymentSliceState) => {
			payment.loading = true;
			payment.transfers = [];
			payment.errors = null;
		},
		loadMyTransfersFailed: (payment: PaymentSliceState, action: PayloadAction<any>) => {
			payment.loading = false;
			payment.errors = action.payload;
		},
		myTransfersLoaded: (payment: PaymentSliceState, action: PayloadAction<TransferConnection>) => {
			payment.loading = false;
			payment.transfers = action.payload.edges
				.map((edge) => edge.node)
				.sort((a: Transfer, b: Transfer) => {
					return new Date(b.succeeded_at).getTime() - new Date(a.succeeded_at).getTime();
				});
			payment.transfersPageInfo = action.payload.page_info;
		},
		obtainMerchantAccountDashboardLinkRequested: (payment: PaymentSliceState) => {
			payment.requestingMerchantAccountExternalUrl = true;
			payment.errors = null;
		},
		obtainMerchantAccountDashboardLinkFailed: (payment: PaymentSliceState, action: PayloadAction<any>) => {
			payment.requestingMerchantAccountExternalUrl = false;
			payment.loading = false;
			payment.errors = action.payload;
		},
		merchantAccountDashboardLinkObtained: (payment: PaymentSliceState) => {
			payment.requestingMerchantAccountExternalUrl = false;
			payment.loading = false;
		},
		stripeConnectRequested: (payment: PaymentSliceState) => {
			payment.loadingConnectToStripeAccount = true;
		},
		stripeConnectFailed: (payment: PaymentSliceState, errors: any) => {
			payment.loadingConnectToStripeAccount = false;
			payment.errors = errors;
		},
		stripeConnectLinked: (payment: PaymentSliceState) => {
			payment.loadingConnectToStripeAccount = false;
		},
		requestPayoutRequested: (payment: PaymentSliceState) => {
			payment.loadingRequestPayout = true;
		},
		requestPayoutFailed: (payment: PaymentSliceState, errors: any) => {
			payment.loadingRequestPayout = false;
			payment.errors = errors;
		},
		payoutRequested: (payment) => {
			payment.loadingRequestPayout = false;
		},
		openVirtualBankAccountRequested: (payment: PaymentSliceState) => {
			payment.loading = true;
		},
		openVirtualBankAccountFailed: (payment: PaymentSliceState, errors: any) => {
			payment.loading = false;
			payment.errors = errors;
		},
		virtualBankAccountOpened: (payment) => {
			payment.loading = false;
		},
		preparePaymentRequested: (payment: PaymentSliceState) => {
			payment.loading = true;
			payment.preparedPayment = null;
		},
		preparePaymentFailed: (payment: PaymentSliceState, errors: any) => {
			payment.loading = false;
			payment.errors = errors;
		},
		paymentPrepared: (payment: PaymentSliceState, action: PayloadAction<Payment>) => {
			payment.loading = false;
			payment.preparedPayment = action.payload;
		},
	},
});

export const {
	loadMyTransfersRequested,
	loadMyTransfersFailed,
	myTransfersLoaded,
	obtainMerchantAccountDashboardLinkRequested,
	obtainMerchantAccountDashboardLinkFailed,
	merchantAccountDashboardLinkObtained,
	stripeConnectRequested,
	stripeConnectFailed,
	stripeConnectLinked,
	requestPayoutRequested,
	requestPayoutFailed,
	payoutRequested,
	openVirtualBankAccountRequested,
	openVirtualBankAccountFailed,
	virtualBankAccountOpened,
	preparePaymentRequested,
	preparePaymentFailed,
	paymentPrepared,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

export const loadMyTransfers =
	({ before, after, first, last }: QueryMyTransfersArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log('Load my transfers');

			dispatch(loadMyTransfersRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { transfer_connection, errors } = await randevuService.payments.myTransfers({
				before,
				after,
				first,
				last,
			});

			if (isSessionExpired(errors))
				return requestReauthentication({ callbackThunkKey: THUNKS.LOAD_MY_TRANSFERS.key });

			if (errors) {
				return dispatch(loadMyTransfersFailed(errors));
			}

			return dispatch(myTransfersLoaded(transfer_connection));
		};

export const redirectExternal =
	({ url, keepHistory }: { url: string; keepHistory: boolean }) =>
		(dispatch: AppDispatch) => {
			if (keepHistory) return window.location.assign(url);

			return window.location.replace(url);
		};

export const requestStripeDashboardLink =
	({ id_merchant_account }: MutationRequestStripeDashboardSignInLinkArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(obtainMerchantAccountDashboardLinkRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { url, errors } = await randevuService.payments.requestStripeDashboardSignInLink({
				id_merchant_account,
			});

			if (isSessionExpired(errors))
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.REQUEST_STRIPE_DASHBOARD_LINK.key,
						payload: { id_merchant_account },
					})
				);
			if (errors || !url) {
				return dispatch(obtainMerchantAccountDashboardLinkFailed(errors));
			}

			dispatch(merchantAccountDashboardLinkObtained());
			return dispatch(redirectExternal({ url, keepHistory: true }));
		};

export const linkParticipantAccountToStripeConnectAccount =
	() => async (dispatch: AppDispatch, getState: () => RootState) => {
		const id_integration_provider = RANDEVU_IDS.INTEGRATION_PROVIDERS.STRIPE; //FIXME @ROKVA - create const
		const refresh_url = 'https://www.app.aquga.com'
			.toString()
			.concat(ROUTES.PARTICIPANT_ACCOUNT.concat('?currentTab="finances"'));
		const return_url = refresh_url;

		dispatch(stripeConnectRequested());

		const randevuService = new randevu({ token: getState().auth.token });

		const { url, errors } = await randevuService.payments.requestStripeConnectAccountConnection({
			//SOMETHING SMELLS WITH THIS URL, DOUBLE CHECK
			id_integration_provider,
			refresh_url,
			return_url,
		});

		if (isSessionExpired(errors)) {
			dispatch(
				requestReauthentication({
					callbackThunkKey: THUNKS.REQUEST_STRIPE_CONNECT.key, //add thunk key
				})
			);
			return dispatch(stripeConnectFailed(errors));
		}

		if (errors) {
			return dispatch(stripeConnectFailed(errors));
		}

		dispatch(stripeConnectLinked());
		return dispatch(redirectExternal({ url, keepHistory: true }));
	};

interface OpenVirtualBankAccountRequest {
	id_participant: string;
	id_integration_provider?: string;
	country_code?: string;
	currency?: CurrencyKind;
}

export const openVirtualBankAccount =
	({
		id_participant,
		id_integration_provider = RANDEVU_IDS.INTEGRATION_PROVIDERS.STRIPE,
		country_code = 'DE',
		currency = CurrencyKind.Eur,
	}: OpenVirtualBankAccountRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(openVirtualBankAccountRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { success, errors } = await randevuService.payments.openVirtualBankAccount({
				id_integration_provider,
				id_participant,
				country_code,
				currency,
			});

			if (isSessionExpired(errors)) {
				dispatch(
					requestReauthentication({
						callbackThunkKey: THUNKS.OPEN_VIRTUAL_BANK_ACCOUNT.key,
					})
				);
				dispatch(openVirtualBankAccountFailed(errors));
			}

			if (errors) {
				dispatch(openVirtualBankAccountFailed(errors));
			}

			dispatch(virtualBankAccountOpened());
		};

export const requestPayout =
	({ id_merchant_account }: MutationRequestPayoutArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Request payout from ${id_merchant_account}`);

			dispatch(requestPayoutRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { requested, errors } = await randevuService.payments.requestPayout({
				id_merchant_account,
			});

			if (isSessionExpired(errors))
				return requestReauthentication({
					callbackThunkKey: THUNKS.REQUEST_PAYOUT.key,
					payload: { id_merchant_account },
				});

			if (errors) {
				return dispatch(requestPayoutFailed(errors));
			}
			if (requested !== true) {
				return dispatch(requestPayoutFailed(errors));
			}

			return dispatch(payoutRequested());
		};
export const preparePaymentAndRequestCheckout =
	({ id_transaction, tech_name = RANDEVU_IDS.PAYMENT_TYPES.SEPA.tech_name }: MutationPrepareTransactionPaymentArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(preparePaymentRequested());

			const randevuService = new randevu({ token: getState().auth.token });

			const { payment, errors } = await randevuService.payments.prepareTransactionPayment({
				id_transaction,
				tech_name,
			});

			if (isSessionExpired(errors))
				return requestReauthentication({
					callbackThunkKey: THUNKS.REQUEST_PAYOUT.key,
					payload: { id_transaction, tech_name },
				});

			if (!isEmpty(errors) || !payment) {
				return dispatch(preparePaymentFailed(errors));
			}

			const { transaction, errors: transactionErrors } = await randevuService.transactions.myTransaction({
				id: id_transaction,
			});

			if (!isEmpty(transactionErrors)) {
				return dispatch(preparePaymentFailed(transactionErrors));
			}

			dispatch(myMatchLoaded(transaction));

			const { payment: checkoutPayment, errors: checkoutErrors } =
			await randevuService.payments.requestBankTransferCheckout({ id_payment: payment.id });

			if (!isEmpty(checkoutErrors) || !checkoutPayment) {
				return dispatch(preparePaymentFailed(checkoutErrors));
			}

			return dispatch(paymentPrepared(payment));
		};

/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state: RootState) => state.payment.loading;
export const selectLoadingRequestPayout = (state: RootState) => state.payment.loadingRequestPayout;
export const selectLoadingConnectToStripeAccount = (state: RootState) => state.payment.loadingConnectToStripeAccount;
export const selectTransfers = (state: RootState) => state.payment.transfers;
export const selectTransfersPageInfo = (state: RootState) => state.payment.transfersPageInfo;
export const selectRequestingMerchantAccountExternalUrl = (state: RootState) =>
	state.payment.requestingMerchantAccountExternalUrl;
