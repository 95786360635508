import { SECURITY_FORMS_MAX_WIDTH } from '@aquga/app/constants';
import { isBusinessSide } from '@aquga/app/participantHelpers';
import { validator } from '@aquga/app/validators';
import CallToSignUp from '@aquga/components/login/CallToSignUp';
import LoginForm, { LoginFormValues } from '@aquga/components/login/LoginForm';
import SecurityPageLayout from '@aquga/components/security/SecurityPageLayout';
import { ROUTES } from '@aquga/config/routes';
import { PARTICIPANT_STATUS_KIND } from '@aquga/services/randevuApi';
import { useAppDispatch, useAppSelector } from '@aquga/store/configureStore';
import { loginParticipant, loginParticipantFailed, selectLoading } from '@aquga/store/slices/authSlice';
import { selectCurrentParticipant } from '@aquga/store/slices/participantSlice';
import { AQUGA_LOGIN_GRAY } from '@aquga/styles/theme';
import { Divider, Grid, Theme, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
	const intl = useIntl();
	const metaTitle = `${intl.formatMessage({ id: 'loginPage.metaTitle' })} - AQUGA`;
	const theme: Theme = useTheme();

	const loading = useAppSelector(selectLoading);
	const currentParticipant = useAppSelector(selectCurrentParticipant);
	const isParticipantBusinessSide = isBusinessSide(currentParticipant);
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const handleSubmitLoginForm = async (values: LoginFormValues) => {
		const resolvedAction = await dispatch(loginParticipant({ ...values }));

		if (resolvedAction.type === loginParticipantFailed.type) {
			const submissionErrors = { ...resolvedAction.payload };

			// TODO: write nice helper function which does this one
			Object.entries(submissionErrors).map((keyValue) => {
				submissionErrors[keyValue[0]] = intl.formatMessage({ id: keyValue[1] as string });
			});

			return submissionErrors;
		}
	};

	const validateLoginForm = (values: LoginFormValues) => {
		const fieldValidators = {
			email: [
				(value: any) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
				(value: any) =>
					validator.isEmail({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.invalidEmail' }),
					}),
			],

			password: [
				(value: any) =>
					validator.required({
						value,
						errorMsg: intl.formatMessage({ id: 'validation.general.mandatoryField' }),
					}),
			],
		};

		return validator.doValidation(fieldValidators, values);
	};

	useEffect(() => {
		if (PARTICIPANT_STATUS_KIND.ONBOARDING.value === currentParticipant?.status) {
			navigate(ROUTES.PARTICIPANT_ONBOARDING);
			return;
		}
		if (PARTICIPANT_STATUS_KIND.REJECTED.value === currentParticipant?.status) {
			navigate(ROUTES.PARTICIPANT_REJECTED);
			return;
		}

		if (currentParticipant) {
			navigate(ROUTES.DASHBOARD);
		}
	}, [currentParticipant, navigate]);

	return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
			</Helmet>
			<SecurityPageLayout backgroundColor={AQUGA_LOGIN_GRAY}>
				<Grid
					container
					justifyContent="center"
					maxWidth={SECURITY_FORMS_MAX_WIDTH}
					marginLeft="auto"
					marginRight="auto"
					display="flex"
				>
					<Grid item xs={12} marginBottom={theme.spacing(8)}>
						<LoginForm loading={loading} onSubmit={handleSubmitLoginForm} onValidate={validateLoginForm} />
					</Grid>
					<Grid item xs={10} marginBottom={theme.spacing(4)}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<CallToSignUp />
					</Grid>
				</Grid>
				<br />
				<br />
			</SecurityPageLayout>
		</>
	);
};
export default LoginPage;
