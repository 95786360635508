import authReducer, { participantLoggedOut } from '@aquga/store/slices/authSlice';
import participantReducer from '@aquga/store/slices/participantSlice';
import paymentReducer from '@aquga/store/slices/paymentSlice';
import supplyReducer from '@aquga/store/slices/supplySlice';
import transactionReducer from '@aquga/store/slices/transactionSlice';
import uiReducer from '@aquga/store/slices/uiSlice';
import { AnyAction, combineReducers } from 'redux';

const appReducer = combineReducers({
	ui: uiReducer,
	auth: authReducer,
	participant: participantReducer,
	transaction: transactionReducer,
	supply: supplyReducer,
	payment: paymentReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
	if (action.type === participantLoggedOut.type) {
		state = undefined;
	}
	return appReducer(state, action);
};
export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
