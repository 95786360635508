import { QueryMyTransactionArgs, QueryMyTransfersArgs } from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

// const OPEN_VIRTUAL_BANK_ACCOUNT = gql`
// 	query OPEN_VIRTUAL_BANK_ACCOUNT {
// 		myTransactions {
// 			id
// 			id_parent_transaction
// 			state {
// 				status_tech_name
// 			}
// 			created_at
// 			terminated_at
// 			initiated_at
// 			prepared_at
// 			sub_transactions_count
// 			order_fields {
// 				... on SimpleField {
// 					simpleValue: value
// 					field_type {
// 						tech_name
// 					}
// 				}
// 				... on ObjectField {
// 					objectValue: value {
// 						id_object
// 						fields {
// 							... on SimpleField {
// 								simpleValue: value
// 								field_type {
// 									tech_name
// 								}
// 							}
// 						}
// 					}
// 					field_type {
// 						tech_name
// 					}
// 				}

// 				... on ObjectSetField {
// 					id
// 					objectSetValue: value {
// 						id_object
// 						fields {
// 							... on SimpleField {
// 								simpleValue: value
// 								field_type {
// 									tech_name
// 								}
// 							}
// 						}
// 					}
// 					field_type {
// 						tech_name
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

// const myTransactions = () => {
// 	return JSON.stringify({
// 		query: print(MY_TRANSACTIONS),
// 	});
// };
const MY_TRANSFERS = gql`
	query MY_TRANSFERS($where: TransferFilter, $before: String, $after: String, $first: Int, $last: Int) {
		myTransfers(where: $where, before: $before, after: $after, first: $first, last: $last) {
			total_count
			page_info {
				end_cursor
				start_cursor
				has_next_page
				has_previous_page
			}
			edges {
				cursor
				node {
					id
					amount {
						amount
						currency
					}
					error
					order {
						id
						net_value
						gross_value
						currency
						order_items {
							value
							description
							type
						}
					}
					status
					succeeded_at
				}
			}
		}
	}
`;

const myTransfers = ({ where, after, before, first, last }: QueryMyTransfersArgs) => {
	return JSON.stringify({
		query: print(MY_TRANSFERS),
		variables: { where, after, before, first, last },
	});
};

const MY_TRANSACTION = gql`
	query MY_TRANSACTION($id: ID!) {
		myTransaction(id: $id) {
			id
			id_parent_transaction
			state {
				status_tech_name
			}
			matches {
				supply_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				consumer_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				provider_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
			}
			created_at
			terminated_at
			state {
				message
				next_steps {
					type
					config
					action_message
				}
			}
			sub_transactions {
				id
				state {
					status_tech_name
				}
				created_at
				provider_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
			}
			type {
				id
				type
			}
			order {
				fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				supply_filter {
					value
					tech_name
				}
			}
		}
	}
`;

const myTransaction = ({ id }: QueryMyTransactionArgs) => {
	return JSON.stringify({
		query: print(MY_TRANSACTION),
		variables: {
			id: String(id),
		},
	});
};

export { myTransaction, myTransfers };
