// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ASSETS, DRAWER_WIDTH, LAYOUT_PADDING, MOBILE_APPBAR_HEIGHT } from '@aquga/app/constants';
import { ROUTES } from '@aquga/config/routes';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import LanguagePicker from './LanguagePicker';
import NavigationDrawer from './NavigationDrawer';

const StyledToolbar = styled(Toolbar)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: LAYOUT_PADDING,
});

function Navbar() {
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<>
			<AppBar
				elevation={0}
				component="nav"
				position="sticky"
				sx={{
					background: 'white',
					height: { xs: MOBILE_APPBAR_HEIGHT, sm: MOBILE_APPBAR_HEIGHT, md: 'inherit' },
				}}
			>
				<StyledToolbar disableGutters>
					<IconButton
						color="primary"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { md: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Box sx={{ mr: 'auto', ml: 'auto', display: { md: 'none' } }}>
						<Link to={ROUTES.HOME_PAGE}>
							<img alt="AQUGA Logo" style={{ width: 200, height: 80 }} src={ASSETS.AQUGA_LOGO_MAIN} />
						</Link>
					</Box>
					<Box sx={{ mr: 'auto', display: { xs: 'none', sm: 'none', md: 'block' } }}>
						<Link to={ROUTES.HOME_PAGE}>
							<img alt="AQUGA Logo" style={{ width: 200, height: 80 }} src={ASSETS.AQUGA_LOGO_MAIN} />
						</Link>
					</Box>
					<Stack
						direction="row"
						sx={{
							display: { xs: 'none', sm: 'none', md: 'block' },
							marginLeft: 'auto',
						}}
						spacing={2}
					>
						<Button
							component={Link}
							to={ROUTES.SIGN_UP_BUSINESS_PAGE}
							variant="contained"
							color="quaternary"
						>
							<FormattedMessage id="common.btnAskQuestions" />
						</Button>

						<Button
							component={Link}
							to={ROUTES.SIGN_UP_CONSULTING_HOUSE_PAGE}
							variant="contained"
							color="tertiary"
						>
							<FormattedMessage id="common.btnAnswerQuestions" />
						</Button>

						<Button
							component={Link}
							variant="text"
							color="secondary"
							to={ROUTES.SIGN_IN_PAGE}
							sx={{ backgroundColor: 'transparent' }}
						>
							<FormattedMessage id="common.btnSignIn" />
						</Button>
						<LanguagePicker />
					</Stack>
				</StyledToolbar>
			</AppBar>
			<Box component="nav">
				<NavigationDrawer open={mobileOpen} drawerWidth={DRAWER_WIDTH} onDrawerToggle={handleDrawerToggle} />
			</Box>
		</>
	);
}

export default Navbar;
