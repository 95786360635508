import { loadCurrentParticipant, onboardParticipantFields, updateMyFields } from '@aquga/store/slices/participantSlice';
import { createSupply, loadMySupplies, loadMySupply, updateSupply } from '@aquga/store/slices/supplySlice';
import {
	acceptTransactedMatch,
	acceptTransactedTicket,
	confirmRequirementsAndSetAgreedPrice,
	createTicket,
	declineContract,
	declineTransactedMatch,
	declineTransactedTicket,
	loadMyTicket,
	loadMyTickets,
	offerContract,
	presentSolution,
	requestChanges,
	triggerManualMatchTransition,
	updateRequirementsAndRejectTicket,
	updateRequirementsAndTransitionToChangesReceived,
	updateScheduledMeetingTimeAndTriggerTransition,
	updateTransactedMatch,
	pollMyTicketMatchesAsBusinessAndTicketDetailsAsConsultant,
	triggerConsumerManualTransactionTransition,
	pollMyQuestionMatchDetails,
	acceptContract,
	withdrawFromTicketProvider,
	applyForTicketProvider,
	loadMyMatch,
} from '@aquga/store/slices/transactionSlice';

import {
	linkParticipantAccountToStripeConnectAccount,
	loadMyTransfers,
	openVirtualBankAccount,
	requestStripeDashboardLink,
} from './slices/paymentSlice';

export interface ThunkDefinition {
	key: string;
	getThunk: () => any;
}

const THUNKS: { [key: string]: ThunkDefinition } = {
	//Participant
	LOAD_CURRENT_PARTICIPANT: {
		key: 'LOAD_CURRENT_PARTICIPANT',
		getThunk: () => loadCurrentParticipant,
	},
	ONBOARD_PARTICIPANT_FIELDS: {
		key: 'ONBOARD_PARTICIPANT_FIELDS',
		getThunk: () => onboardParticipantFields,
	},
	UPDATE_MY_FIELDS: {
		key: 'UPDATE_MY_FIELDS',
		getThunk: () => updateMyFields,
	},
	CREATE_TICKET: {
		key: 'CREATE_TICKET',
		getThunk: () => createTicket,
	},
	LOAD_MY_TICKETS: {
		key: 'LOAD_MY_TICKETS',
		getThunk: () => loadMyTickets,
	},
	LOAD_MY_TRANSFERS: {
		key: 'LOAD_MY_TRANSFERS',
		getThunk: () => loadMyTransfers,
	},
	LOAD_MY_TICKET: {
		key: 'LOAD_MY_TICKET',
		getThunk: () => loadMyTicket,
	},
	LOAD_MY_SUPPLIES: {
		key: 'LOAD_MY_SUPPLIES',
		getThunk: () => loadMySupplies,
	},
	CREATE_SUPPLY: {
		key: 'CREATE_SUPPLY',
		getThunk: () => createSupply,
	},
	UPDATE_SUPPLY: {
		key: 'UPDATE_SUPPLY',
		getThunk: () => updateSupply,
	},
	LOAD_MY_SUPPLY: {
		key: 'LOAD_MY_SUPPLY',
		getThunk: () => loadMySupply,
	},
	TRIGGER_MANUAL_TRANSACTION_TRANSITION: {
		key: 'TRIGGER_MANUAL_TRANSACTION_TRANSITION',
		getThunk: () => triggerManualMatchTransition,
	},
	ACCEPT_MATCH: {
		key: 'ACCEPT_MATCH',
		getThunk: () => acceptTransactedMatch,
	},
	DECLINE_MATCH: {
		key: 'DECLINE_MATCH',
		getThunk: () => declineTransactedMatch,
	},
	REQUEST_CHANGES: {
		key: 'REQUEST_CHANGES',
		getThunk: () => requestChanges,
	},
	DECLINE_CONTRACT: {
		key: 'DECLINE_CONTRACT',
		getThunk: () => declineContract,
	},
	PRESENT_SOLUTION: {
		key: 'PRESENT_SOLUTION',
		getThunk: () => presentSolution,
	},
	OFFER_CONTRACT: {
		key: 'OFFER_CONTRACT',
		getThunk: () => offerContract,
	},
	CONFIRM_REQUIREMENTS_AND_SET_AGREED_PRICE: {
		key: 'CONFIRM_REQUIREMENTS_AND_SET_AGREED_PRICE',
		getThunk: () => confirmRequirementsAndSetAgreedPrice,
	},
	UPDATE_SCHEDULED_MEETING_TIME_AND_TRIGGER_TRANSITION: {
		key: 'UPDATE_SCHEDULED_MEETING_TIME_AND_TRIGGER_TRANSITION',
		getThunk: () => updateScheduledMeetingTimeAndTriggerTransition,
	},
	UPDATE_REQUIREMENTS_AND_TRANSITION_TO_CHANGES_RECIEVED: {
		key: 'UPDATE_REQUIREMENTS_AND_TRANSITION_TO_CHANGES_RECIEVED',
		getThunk: () => updateRequirementsAndTransitionToChangesReceived,
	},
	UPDATE_REQUIREMENTS_AND_REJECT_TICKET: {
		key: 'UPDATE_REQUIREMENTS_AND_REJECT_TICKET',
		getThunk: () => updateRequirementsAndRejectTicket,
	},
	UPDATE_TRANSACTED_MATCH: {
		key: 'UPDATE_TRANSACTED_MATCH',
		getThunk: () => updateTransactedMatch,
	},
	DECLINE_TRANSACTED_TICKET: {
		key: 'DECLINE_TRANSACTED_TICKET',
		getThunk: () => declineTransactedTicket,
	},
	ACCEPT_TRANSACTED_TICKET: {
		key: 'ACCEPT_TRANSACTED_TICKET',
		getThunk: () => acceptTransactedTicket,
	},
	TRIGGER_MANUAL_MATCH_TRANSITION: {
		key: 'TRIGGER_MANUAL_MATCH_TRANSITION',
		getThunk: () => triggerManualMatchTransition,
	},
	POLL_DETAILS: {
		key: 'POLL_DETAILS',
		getThunk: () => pollMyTicketMatchesAsBusinessAndTicketDetailsAsConsultant,
	},
	TRIGGER_CONSUMER_MANUAL_TRANSACTION_TRANSITION: {
		key: 'TRIGGER_CONSUMER_MANUAL_TRANSACTION_TRANSITION',
		getThunk: () => triggerConsumerManualTransactionTransition,
	},
	POLL_MATCH_DETAILS: {
		key: 'POLL_MATCH_DETAILS',
		getThunk: () => pollMyQuestionMatchDetails,
	},
	ACCEPT_CONTRACT: {
		key: 'ACCEPT_CONTRACT',
		getThunk: () => acceptContract,
	},
	WITHDRAW_FROM_TICKET_PROVIDER: {
		key: 'WITHDRAW_FROM_TICKET_PROVIDER',
		getThunk: () => withdrawFromTicketProvider,
	},
	APPLY_FOR_TICKET_PROVIDER: {
		key: 'APPLY_FOR_TICKET_PROVIDER',
		getThunk: () => applyForTicketProvider,
	},
	LOAD_MY_MATCH: {
		key: 'LOAD_MY_MATCH',
		getThunk: () => loadMyMatch,
	},

	//PAYMENTS
	REQUEST_STRIPE_DASHBOARD_LINK: {
		key: 'REQUEST_STRIPE_DASHBOARD_LINK',
		getThunk: () => requestStripeDashboardLink,
	},
	OPEN_VIRTUAL_BANK_ACCOUNT: {
		key: 'OPEN_VIRTUAL_BANK_ACCOUNT',
		getThunk: () => openVirtualBankAccount,
	},
	REQUEST_STRIPE_CONNECT: {
		key: 'REQUEST_STRIPE_CONNECT',
		getThunk: () => linkParticipantAccountToStripeConnectAccount,
	},
};

export default THUNKS;
