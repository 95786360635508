import { QueryMyTransactionArgs } from '@aquga/services/randevuApi/generatedTypes';
import { print } from 'graphql';
import gql from 'graphql-tag';

const MY_TRANSACTIONS = gql`
	query MY_TRANSACTIONS {
		myTransactions {
			id
			id_parent_transaction
			state {
				status_tech_name
			}
			created_at
			terminated_at
			initiated_at
			prepared_at
			sub_transactions_count
			order_fields {
				... on SimpleField {
					simpleValue: value
					field_type {
						tech_name
					}
				}
				... on ObjectField {
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									tech_name
								}
							}
						}
					}
					field_type {
						tech_name
					}
				}

				... on ObjectSetField {
					id
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									tech_name
								}
							}
						}
					}
					field_type {
						tech_name
					}
				}
			}
		}
	}
`;

const myTransactions = () => {
	return JSON.stringify({
		query: print(MY_TRANSACTIONS),
	});
};
const MY_TRANSFERS = gql`
	query MY_TRANSFERS {
		myTransfers {
			id
			amount {
				amount
				currency
			}
			order {
				id
				net_value
				gross_value
				currency
				order_items {
					value
					description
					type
				}
			}
			status
			succeeded_at
		}
	}
`;

const myTransfers = () => {
	return JSON.stringify({
		query: print(MY_TRANSFERS),
	});
};

const MY_TRANSACTION = gql`
	query MY_TRANSACTION($id: ID!) {
		myTransaction(id: $id) {
			id
			id_parent_transaction
			state {
				status_tech_name
			}
			chargeable {
				total_before_tax
				total_taxes
				total_to_charge
			}
			payments {
				id
				amount {
					amount
					currency
				}
				payment_method_details
			}
			matches {
				id
				supply_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				consumer_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				provider_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
			}
			created_at
			terminated_at
			state {
				message
				next_steps {
					type
					config
					action_message
				}
			}
			sub_transactions {
				id
				state {
					status_tech_name
				}

				created_at
				provider_fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
			}
			type {
				id
				type
			}
			order {
				fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							tech_name
						}
					}
					... on ObjectField {
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}

					... on ObjectSetField {
						id
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										tech_name
									}
								}
							}
						}
						field_type {
							tech_name
						}
					}
				}
				supply_filter {
					value
					tech_name
				}
			}
		}
	}
`;

const myTransaction = ({ id }: QueryMyTransactionArgs) => {
	return JSON.stringify({
		query: print(MY_TRANSACTION),
		variables: {
			id: String(id),
		},
	});
};

export { myTransaction, myTransactions, myTransfers };
