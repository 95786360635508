const messages_de = {
	common: {
		onboardingStepper: 'Schritt',
		withdraw: 'Bewerbung zurückziehen',
		btnSignIn: 'Einloggen',
		btnSignOut: 'Ausloggen',
		btnAskQuestions: 'Fragen stellen',
		btnAnswerQuestions: 'Fragen beantworten',
		btnAddService: 'Dienst hinzufügen',
		termsOfService: 'Nutzungsbedingungen',
		privacyPolicy: 'Datenschutz-Bestimmungen',
		cookiePolicy: 'Cookie-Richtlinie zu',
		btnAddAnsweringExpertise: 'Lösungskompetenzen hinzufügen',
		sourcedTalentMatchingAgreement: 'Sourced Talent Matching Vereinbarung',
		back: 'Zurück',
		open: 'Öffnen',
		cancel: 'Abbrechen',
		save: 'Speichern',
		account: 'Konto',
		and: 'und',
		comingSoon: 'Demnächst...',
		dropZone: {
			title: 'Hier ablegen oder Dateien durchsuchen',
			fileFormat: 'Dateiformat: {fileFormat}',
			fileSize: 'Max. Dateigröße: {maxFileSize} mb',
			maxFiles: 'Max. {maxFiles} Dateien',
			release: 'Loslassen!',
			generalErrors: 'Unzulässige Datei(en)!',
		},
	},
	pointOfContact: {
		title: 'Kontaktperson',
	},
	myAnsweringExpertise: {
		title: 'Lösungskompetenzen',
	},
	personalInfo: {
		title: 'Persönliche Informationen',
	},
	participantAccountPage: {
		metaTitle: 'Ihr Konto',

		title: 'Konto',
		tabs: {
			general: 'Allgemein',
			finances: 'Finanzen',
		},
		finances: {
			merchantAccount: {
				linkAccounts: {
					linkBtn: 'Konten verlinken',
					bannerText:
						'Damit Sie das Geld von unserer Seite erhalten können, müssen Sie Ihr Konto mit einem Händlerkonto bei Stripe verknüpfen.',
					bannerTitle: 'Bitte verknüpfen Sie Ihr Händlerkonto mit Stripe',
				},
				continueLinkingAccounts: {
					continueBtn: 'Zu Stripe gehen',
					bannerText:
						'Stripe kann Ihr Onboarding nicht abschließen, da es weitere Informationen von Ihnen benötigt. Bitte gehen Sie zu Stripe, um die Verbindung mit Ihrem Konto abzuschließen.',
					bannerTitle: 'Zusätzliche Informationen für Ihr Stripe-Konto erforderlich',
				},
				linkedAccounts: {
					title: 'Händlerkonto',
					viewAccountBtn: 'Kontodetails anzeigen',
					requestPayoutBtn: 'Auszahlung anfordern',
				},
			},
			transfer: {
				detailsDialog: {
					title: 'Überweisungsdetails',
					gross: 'Bruttobetrag',
					takerate: 'Plattformgebühr',
					taxes: 'Steuer',
					earning: 'Nettobetrag',
				},
				table: {
					columns: {
						sent: 'Gesendet',
						amount: 'Betrag',
						status: 'Status',
						orderId: 'Bestell-ID',
					},
					transferStatus: {
						created: 'Vorbereitet',
						failed: 'Fehlgeschlagen',
						succeeded: 'Überwiesen',
					},
					emptyStateText: 'No transfers yet',
				},
			},
		},
	},
	navigation: {
		consumer: {
			tickets: 'Fragen',
			createTickets: 'Fragen stellen',
			titleConsultant: 'Antwortender',
			titleAsker: 'Fragender',
		},
		menu: 'Menü',
		dashboard: 'Dashboard',
		contracts: 'Verträge',
		answeringExpertise: 'Lösungskompetenz angeben',
		productOwner: 'Ein Produkt der ix3 GmbH',
	},

	langs: {
		de: 'De',
		german: 'Deutsch',
		en: 'En',
		english: 'Englisch',
		english_us: 'Englisch (Vereinigte Staaten)',
	},
	footer: {
		contactUs: 'Kontaktieren Sie uns',
		faq: 'Häufig gestellte Fragen (FAQ)',
		title: 'Ask questions get answers',
		followUs: 'Folge uns',
		copyRight: 'Alle Rechte vorbehalten © 2022 - {currentYear} ix3 GmbH',
	},
	home: {
		hero: {
			titleFirstLine: 'Wir',
			titleSecondLine: 'liefern',
			titlePoints: ['Spezialisten', 'Mehrwert', 'Expertise', 'Transparenz'],
			subTitle: 'Stellen Sie Fragen, erhalten Sie Antworten!',
			explainer:
				'Wir glauben, dass eine Kombination aus Branchenexpertise und funktionalem Wissen für eine erfolgreiche SAP-Implementierung entscheidend ist.',
		},
		usp: {
			eyebrow: 'Der Vorteil',
			title: 'Title würde hier gehen',
			points: [
				'Schnellere Zeit bis zur Lösung',
				'Niedrigere Kosten',
				'Minimierte Risiken',
				'Höhere Erfolgsquote',
				'Mehr Transparenz',
			],
		},
		business: {
			eyebrow: 'Unser versprochener Wert',
			title: 'Lösungen mit großer Wirkung für Ihr Unternehmen',
			explainerPart01:
				'Heute zahlen Sie den Preis nach Zeitaufwand. Der Preis der Lösung wird erst am Ende transparent',
			explainerPart02: 'Bei',
			explainerPart03: 'zahlen Sie den Preis für die Lösung, die Sie für wertvoll halten!​',
		},
		consultant: {
			eyebrow: 'FÜR WEN IST DAS',
			title: 'Finden Sie die richtige Herausforderung für Sie!',
			explainerPart01: 'Wenn Sie ein erfahrener SAP-Berater sind und hochwertige SAP-Softwareentwicklung bei ',
			explainerPart02:
				'für Beratungsdienste für globale Unternehmen anbieten möchten, schließen Sie sich uns an!',
		},
		howItWorks: {
			eyebrow: 'Wie es funktioniert',
			title: 'So erhalten Sie Unterstützung von SAP-Experten',
			titlePoint1: 'Titel geht hier 1',
			explainerPoint1:
				'Beschreibung Absatz kommt hier Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh',
			titlePoint2: 'Titel geht hier 2',
			explainerPoint2:
				'Beschreibung Absatz kommt hier Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh',
			titlePoint3: 'Titel geht hier 3',
			explainerPoint3:
				'Beschreibung Absatz kommt hier Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh',
		},
	},
	languageDialog: {
		title: 'Sprache auswählen',
	},
	services: {
		list: {
			noServicesAddedyet: 'Es sind noch keine Lösungskompetenzen hinterlegt',
		},
	},
	updateAnsweringExpertisePage: {
		btnSubmit: 'Update der Lösungskompetenz',
		metaTitle: 'Update der Lösungskompetenz',
		title: 'Lösungskompetenz',
		updateAnsweringExpertise: 'Update der Lösungskompetenz',
		moreInfo: 'Bitte geben Sie die gewünschten Updates ein',
	},
	createAnsweringExpertisePage: {
		metaTitle: 'Lösungskompetenz angeben',
		title: 'Kompetenz bei der Beantwortung von Fragen',
		addAnsweringExpertise: 'Lösungskompetenz zur Beantwortung von Fragen hinzufügen',
		moreInfo: 'Nennen Sie uns Ihre Antwortkompetenz, damit wir Ihnen die geeignetste(n) Frage(n) zuordnen können.',
		form: {
			type: { label: 'Welche Technologie unterstützen Sie? *' },
			expertise: { label: 'In welchem Schwerpunktbereich haben Sie Lösungskompetenz? *' },
			understandBackground: { title: 'Helfen Sie der fragenden Person, Ihren Hintergrund zu verstehen' },
			name: { label: 'Titel', placeholder: 'Geben Sie Ihrem Lösungskompetenz einen Titel' },
			description: {
				label: 'Beschreibung',
				placeholder: 'Stellen Sie Ihr Lösungskompetenz so detailliert wie möglich in den Kontext.',
			},
			additionalNote: {
				title: 'Zusätzliche Informationen',
				label: 'Zusätzlicher Hinweis',
				placeholder: 'Ihre Notizen hinzufügen',
			},
		},
	},
	questions: {
		list: {
			title: 'Fragen',
			metaTitle: 'Ihre Fragen',
			update: 'Update',
			noQuestionsAskedYet: 'Sie haben noch keine Fragen gestellt',
			noQuestionsYet: 'Es sind noch keine Fragen vorhanden',
			filter: {
				all: 'Alle',
				active: 'Aktive',
				past: 'Abgeschlossene',
			},
			services: {
				expertise: 'Lösungskompetenz',
				serviceProvided: 'Erbrachte Leistung',
				answeringExpertise: 'Kompetenz bei der Beantwortung von Fragen',
			},
			questionCounter: {
				singular: 'Frage',
				plural: 'Fragen',
			},
			table: {
				columnQuestion: 'Frage',
				columnToBeAnswered: 'Zu beantworten bis',
				value: 'Wert',
				columnMatches: 'Treffer',
				columnStatus: 'Status',
			},
		},
		create: {
			title: 'Neue Frage stellen',
			metaTitle: 'Neue Frage stellen',
			subtitle:
				'Nennen Sie uns Details zu Ihrer Frage, damit wir Ihnen zielgerichtet geeignete Antwortende vermitteln können.',

			sectionTitleProblem: 'Helfen Sie den Beratern, Ihr Anliegen zu verstehen',
			sectionTitleBudget: 'Was ist Ihnen die Lösung Ihrer Frage wert?',
			sectionTitleTopics: 'Zu welchem Themengebiet haben Sie eine Frage?',
			consultantSectionFiles:
				'Fügen Sie alle relevanten Dokumente hinzu (bspw. Projektprofil, Zertifikate etc.), die Ihre Lösungskompetenz beschreiben.',
			businessSectionFiles:
				'Fügen Sie alle relevanten Dokumente hinzu, die Ihre Frage näher beschreiben bzw. spezifizieren',

			sectionTitlePriority: 'Bis wann soll die Frage gelöst sein?',
			sectionTitleService: 'Welche Technologie verwenden Sie? ',
			btnSubmitAnsweringExpertise: 'Speichern',
			sectionTitleAdditionalInfo: 'Zusätzliche Informationen',
			btnSubmitQuestion: 'Frage stellen',
			breadcrumbs: {
				questions: 'Fragen',
				addQuestion: 'Frage stellen',
			},
		},
		details: {
			generic: {
				title: {
					status: 'STATUS',
					matchDate: 'MATCH-DATUM',
					technology: 'TECHNOLOGIE',
					businessFocusArea: 'LÖSUNGSKOMPETENZ',
					additionalInformation: 'ZUSÄTZLICHE INFORMATIONEN',
				},
			},
			metaTitle: 'Frage',
			matches: {
				table: {
					columnProvider: 'Antwortende',
					columnCreatedOn: 'Abgestimmt auf',
					columnStatus: 'Status',
				},
			},
			message_history_accordion: {
				title: 'Kommunikationsverlauf',
				roles: {
					you: 'Sie: ',
				},
			},
			sectionTitleMatches: 'Treffer',
			explainerPoint1: 'Noch keine Treffer. Ihre Frage wartet auf Freigabe.',
			sectionTitleDescription: 'Beschreibung',
			sectionTitleAdditionalNotes: 'Zusätzliche Informationen',
			tabTitleOverview: 'Übersicht',
			tabTitleDescription: 'Beschreibung',
			tabTitleMatches: 'Treffer',
			dueDate: 'Bis zum {dueDate}',
			contract: {
				title: 'Vertrag',
				generating: 'Vertrag generieren',
			},
			answer: {
				title: 'Antwort',
			},
			meeting_proposed_view: {
				title: 'Terminvereinbarung',
				btnProposeNewTime: 'Neue Zeit vorschlagen',
				btnSubmit: 'Termin akzeptieren',
			},
			requested_changes_view: { title: 'Gewünschte Änderungen' },
			agreed_data_view: {
				title: 'Vereinbarte Änderungen',
				description: 'Beschreibung: ',
				price: 'Preis: ',
			},
			meeting_scheduled_view: {
				btnRequestChanges: 'Änderungen beantragen',
				btnSubmit: 'Änderungen bestätigen',
			},
			uploadSolutionView: {
				title: 'Fügen Sie die Antwortdokumente unten hinzu',
				btnSubmit: 'Antwort abschicken',
				previousAnswers: {
					title: 'Vorherige Antwort(en) eingereicht',
					requestedChanges: 'Gewünschte Änderungen',
				},
			},
			answerDeliveredView: {
				btnSubmit: 'Antwort akzeptieren',
				btnRequestChanges: 'Änderungen beantragen',
				requestedChanges: {
					title: 'Gewünschte Änderungen',
				},
			},
			contractOfferedView: {
				btnAccept: 'Vertrag unterschreiben',
				btnDecline: 'Ablehnen',
			},
			openOfferMatchOptions: {
				btnNotInterested: 'Nicht interessiert',
				btnRequestToJoin: 'Bewerben',
			},
			contactDetailsView: {
				title: 'Kontaktinformationen',
				phone: 'Telefonnummer: ',
				email: 'E-Mail: ',
				name: 'Name: ',
			},
		},
		match: {
			options: {
				btnDismiss: 'Ablehnen',
				btnDecideLater: 'Ich entscheide später',
				btnDecline: 'Match ablehnen',
				btnApprove: 'Match annehmen',
			},
			contract: {
				title: 'Vertrag',
				contractOfferingView: {
					title: 'Unterzeichneten Vertrag hinzufügen',
					btnSubmit: 'Unterschreibe den Vertrag',
				},
				matchTimeView: {
					title: 'Kontaktaufnahme',
					body: 'Senden Sie eine Nachricht an den Antwortenden oder vereinbaren Sie einen Termin zur Kontaktaufnahme.',
					btnSendMessage: 'Nachricht senden',
					btnScheduleMeeting: 'Jetzt Termin vereinbaren ',
				},
				proposedTimeView: {
					title: 'Terminvereinbarung',
					btnSendNewProposal: 'Neuen Vorschlag senden',
				},
				scheduledView: {
					title: 'Anforderungen',
					btnUpdateRequirements: 'Anforderungen aktualisieren',
				},
			},
			answer: {
				title: 'Antwort',
			},
			respondent: {
				title: 'Antwortender',
			},
		},
		dialogs: {
			updateRequirementsDialog: {
				common: {
					title: 'Anforderungen aktualisieren',
					body: 'Welche Anforderungen möchten Sie zu dieser Frage ergänzen oder ändern?',
					explainer:
						'Versuchen Sie, so spezifisch wie möglich zu sein. So können Sie sicherstellen, dass die übermittelten Aktualisierungen schneller verstanden werden.',
					btnCancel: 'Abbrechen',
					fields: {
						message: {
							label: 'Beschreibung',
							placeholder: 'Persönliche Nachricht hinzufügen',
						},
					},
				},
				consumer: {
					btnSubmit: 'Aktualisierungen senden',
				},
				provider: {
					btnSubmit: 'Aktualisierungen anfordern',
				},
			},
			declineContractDialog: {
				title: 'Vertrag ablehnen',
				body: 'Sind Sie sicher, dass Sie diesen Vertrag ablehnen wollen?',
				explainer: 'Auf diese Weise können Sie nicht mehr mit diesem Unternehmen zusammenarbeiten.',
				btnCancel: 'Abbrechen',
				btnSubmit: 'Ja, ich bin sicher',
				fields: {
					message: {
						label: 'Grund für die Ablehnung',
						placeholder: 'Fügen Sie Ihre Gründe für die Ablehnung des Vertrags hinzu',
					},
				},
			},
			requestChangeDialog: {
				title: 'Antrag auf Änderung',
				body: 'Welche Verbesserungen möchten Sie an der gegebenen Antwort vornehmen?',
				explainer:
					'Versuchen Sie, so konkret wie möglich zu sein. Dadurch wird sichergestellt, dass die Verbesserungen schneller und entsprechend Ihren Bedürfnissen vorgenommen werden können.',
				btnCancel: 'Abbrechen',
				btnSubmit: 'Anfrage senden',
				fields: {
					improvementNotes: {
						label: 'Anmerkungen zur Verbesserung',
						placeholder: 'Fügen Sie Ihre Verbesserungsvorschläge hinzu',
					},
				},
			},
			scheduleMeetingDialog: {
				title: 'Jetzt Termin vereinbaren ',
				body: 'Termin auswählen',
				btnSubmit: 'Terminvorschlag senden',
				btnCancel: 'Abbrechen',
				form: {
					date: {
						label: 'Datum',
					},
					time: {
						from: 'Von',
						to: 'Bis',
					},
				},
			},
		},
	},
	termsOfService: {
		title: 'Nutzungsbedingungen',
	},
	privacyPolicy: {
		title: 'Datenschutzerklärung',
	},
	sourcedTalentMatchingAgreement: {
		title: 'Sourced Talent Matching Agreement',
	},
	cookiePolicy: {
		title: 'Cookie-Richtlinie zu',
	},
	registerPage: {
		businessSide: {
			metaTitle: 'Registrieren Sie sich als Unternehmen und erhalten Sie Antworten auf Ihre Fragen',
			signUpAsOtherSide: 'Beantworten Sie Fragen?',
			title: {
				normal: 'Erstellen Sie ein Konto,',
				colored: 'um Fragen an Experten zu stellen',
			},
		},
		consultingSide: {
			metaTitle: 'Registrieren Sie sich als Berater und verdienen Sie durch die Beantwortung von Fragen',
			signUpAsOtherSide: 'Stellen Sie Fragen?',
			title: {
				normal: 'Erstellen Sie ein Konto,',
				colored: ' um mit Ihrer Expertise Fragen zu beantworten',
			},
		},
		emailSentView: {
			title: { normal: 'Überprüfen Sie Ihre', colored: 'E-mail' },
			subtitle: 'Fast fertig!',
			explainer:
				'Wir haben Ihnen eine E-Mail mit einem Bestätigungslink gesendet. Klicken Sie auf den Link, um Ihr Konto zu aktivieren. Vergessen Sie nicht, Ihren Spamordner zu überprüfen.',
		},
		form: {
			agreement: 'AQUGA\'s ',
			fields: {
				email: { label: 'Ihre Firmen-E-Mail', placeholder: 'elias.schneider@company.com' },
				firstName: { label: 'Vorname', placeholder: 'Elias' },
				lastName: { label: 'Nachname', placeholder: 'Schneider' },
			},
			btnSignUp: 'Ein Konto erstellen',
		},
		activateAccount: {
			title: {
				normal: 'Konto',
				colored: 'Aktivierung',
			},
			bulletPoint1: 'zwischen 9 und 64 Zeichen',
			bulletPoint2: 'ein Großbuchstabe',
			bulletPoint3: 'ein Kleinbuchstabe',
			bulletPoint4: 'eine Zahl',
			bulletPoint5: 'ein Sonderzeichen',
			passwordExplainer: 'Das Passwort muss enthalten:',
			passwordLabel: 'Passwort erstellen',
			passwordPlaceholder: 'Passwort',
			buttonText: 'Zu den Unternehmensdetails',
		},
		accountReview: {
			title: {
				normal: 'Wir überprüfen gerade',
				colored: 'Ihr Profil',
			},
			subtitle: 'Überprüfen Sie Ihre E-Mails',
			bulletPoint1:
				'Sie erhalten eine E-Mail, wenn die Überprüfung abgeschlossen ist. Es sollte nicht lange dauern.',
			bulletPoint2: 'Vergessen Sie nicht, Ihren Spam-Ordner zu überprüfen.',
		},
		addContactDetails: {
			title: {
				normal: 'Angaben zu Ihrer ',
				colored: 'Kontaktperson Ihres Unternehmens',
			},
			contactPerson: { label: 'Kontaktperson', placeholder: 'Elias Schneider' },
			lastName: { label: 'Nachname', placeholder: 'Scneider' },
			email: { label: 'Unternehmen E-Mail', placeholder: 'elias.schneider@company.com' },
			phoneNumber: { label: 'Rufnummer', placeholder: '+49 30 567371152' },
			buttonText: 'Abschließen',
		},
	},
	reauthenticationDialog: {
		title: 'Deine Sitzung ist abgelaufen',
		body: 'Wenn Sie fortfahren möchten, schreiben Sie Ihr Passwort neu.',
		password: {
			label: 'Passwort',
			placeholder: 'Geben Sie Ihr Passwort ein',
			validation: { incorrectPassword: 'Falsches Kennwort' },
		},
		continueButton: 'Anmeldung',
		cancelButton: 'Abbrechen',
	},

	resources: {
		service: {
			service: {
				label: 'Art der Dienstleistung',
				placeholder: '',
				values: {
					sales_crm: ' Vertrieb/CRM',
					logistics: ' Logistik',
					financials: ' Finanzen',
					production_qm: ' Produktion/QM',
					purchasing: ' Einkaufen',
					warehouse: ' Lager',
					planning: ' Planung',

					analytics: ' Analytik',

					hcm: ' HCM',
					other: ' Andere',
				},
			},
			topics: {
				label: 'Lösungskompetenz',
				placeholder: '',
				values: {
					sap: ' SAP',
					microsoft_erp: ' Microsoft ERP',
					robotics: ' Robotik & Automatisierung',
					other: ' Andere',
				},
			},
		},
		question: {
			service: {
				label: 'Art der Dienstleistung',
				placeholder: '',
				values: {
					sales_crm: ' Vertrieb/CRM',
					logistics: ' Logistik',
					financials: ' Finanzen',
					production_qm: ' Produktion/QM',
					purchasing: ' Einkaufen',
					warehouse: ' Lager',
					planning: ' Planung',
					analytics: ' Analytik',
					hcm: ' HCM',
					other: ' Andere',
				},
			},
			topics: {
				label: 'Lösungskompetenz',
				placeholder: '',
				values: {
					sap: ' SAP',
					microsoft_erp: ' Microsoft ERP',
					robotics: ' Robotik & Automatisierung',
					other: ' Andere',
				},
			},
			title: {
				label: 'Titel',
				placeholder: 'Fassen Sie Ihre Frage in einem prägnanten Satz zusammen',
			},
			description: {
				label: 'Description',
				placeholder: 'Beschreiben Sie ihr Anliegen so detailliert wie möglich',
			},
			price: {
				label: 'Betrag',
				label1: 'Wert',
				placeholder: '3000',
				agreedPrice: 'Vereinbarter Wert',
			},
			internalRefTicket: {
				label: 'Interne Referenz Ticketnummer',
				placeholder: 'Ihre interne Referenz-Ticketnummer, falls Sie diese haben',
			},
			status: {
				label: 'Status',
				placeholder: '',
			},
			createdOn: {
				label: 'Erstellt am',
				placeholder: '',
			},
			priority: {
				label: 'Zeitraum',
				placeholder: '',
				values: {
					asap: 'So schnell wie möglich',
					due_date: 'Wunschdatum',
				},
			},
			additionalNote: {
				label: 'Zusätzliche Anmerkungen',
				placeholder: 'Fügen Sie Ihre Anmerkungen hinzu',
			},
		},
		mainTransaction: {
			stateTypes: {
				open: 'Offen',
				in_progress: 'In Bearbeitung',
				solution_accepted_awaiting_payment: 'Erfordert Zahlung',
				finished: 'Abgeschlossen',
			},
		},
		subTransaction: {
			stateTypes: {
				suggested: 'Vorgeschlagen',
				rejected: 'Abgelehnt',
				opportunity_for_consultant: 'Offen',
				consultant_applied: 'Beworben',
				declined: 'Abgelehnt',
				application_parked: 'Später entscheiden',
				application_dismissed_by_business: 'Nicht interessiert',
				match_time: 'Es gibt ein Match!',
				consultant_not_interested: 'Abgesagt',
				meeting_time_proposed: 'Vorgeschlagener Termin',
				meeting_invite: 'Terminvereinbarung',
				scheduled: 'Termin vereinbart',
				changes_requested: 'Änderungen beantragt',
				changes_received: 'Enthaltene Änderungen',
				contract_offering: 'Vertragsangebot',
				contract_offered: 'Angebotener Vertrag',
				consultant_withdrew: 'Abgesagt',
				business_withdrew_contract: 'Vertrag zurückgezogen',
				contract_accepted: 'Vertrag unterschrieben',
				answer_delivered: 'Antwort geliefert',
				improving_answer: 'Zu verbessernde Antwort',
				answer_accepted: 'Antwort angenommen',
				payment_prepared: 'In Erwartung der Zahlung',
				payment_captued: 'Bezahlt',
				auto_rejected: 'Abgelehnt',
			},
			answer: {
				stateTypes: {
					answering: {
						contract_accepted: 'Antwort abgeben',
					},
					asking: {
						contract_accepted: 'Erwartete Antwort',
					},
				},
			},
		},
	},
	chooseNewPasswordPage: {
		metaTitle: 'Neues Passwort wählen',
		title: { normalTitle: 'Neues ', coloredTitle: 'passwort' },
		fields: { password: { validation: { generalError: 'Bitte versuchen Sie es erneut' } } },
	},
	forgotPasswordPage: {
		metaTitle: 'Passwort vergessen',
		title: { normalTitle: 'Vergessen ', coloredTitle: 'passwort' },
		btnRequestPasswordReset: 'Link zum Zurücksetzen senden',
		bottomBarNormalText: 'Brauchen Sie Hilfe? Kontaktieren Sie ',
		form: {
			email: { label: 'Ihre Firmen-E-Mail', placeholder: 'elias.schneider@company.com' },
		},
		forgotPasswordRequestedView: {
			title: { normalTitle: 'Link zum Zurücksetzen wurde ', coloredTitle: 'gesendet' },
			body: {
				title: 'Prüfen Sie Ihre E-Mail',
				text: 'Eine E-Mail mit dem Link zum Zurücksetzen des Passworts wurde an folgende Adresse gesendet ',
				email: 'name.vorname@unternehmen.com',
				linkExpTime: 'Der Link wird in XXXX Stunden ablaufen.',
			},
			bottomBar: {
				text: 'Zurück zu',
				linkText: 'Einloggen',
			},
		},
	},
	loginPage: {
		metaTitle: 'Anmeldung',
		form: {
			title: { normalTitle: 'Anmelden bei ', coloredTitle: 'AQUGA' },
			email: {
				label: 'Ihre Firmen-E-Mail',
				placeholder: 'elias.schneider@company.com',
				validation: {
					invalidEmail: 'Keine gültige E-Mail Adresse',
					incorrectEmailOrPassword: 'Die angegebene Kombination aus E-Mail-Adresse und Passwort ist ungültig',
				},
			},
			forgotPassword: 'Passwort vergessen',
			buttonText: 'Einloggen',
			password: {
				label: 'Passwort',
				placeholder: 'Dein Passwort',
			},
		},
		bottomBar: {
			title: 'Haben Sie noch kein Konto?',
			btnSignUpAsConsultant: 'Fragen beantworten',
			btnSignUpAsBusiness: 'Fragen stellen',
		},
	},

	validation: {
		general: {
			invalidEmail: 'Ungültige E-Mail',
			mandatoryField: 'Pflichtfeld',
			minLengthThreeCharacters: 'Muss mindestens 3 Zeichen lang sein',
			minLengthFiveCharacters: 'Muss mindestens 5 Zeichen lang sein',
			minLength: 'Bitte wählen Sie mindestens eine Option',
			digitsOnly: 'Nur Ziffern sind erlaubt',
			hourMinuteFormat: 'Format HH:MM erforderlich',
			file: {
				fileTooLarge: 'Datei ist größer als erlaubt.',
				tooManyFiles: 'Es wurden mehr Dateien bereitgestellt als erlaubt.',
				fileInvalidType: 'Dateiformat nicht erlabut.',
			},
		},
	},
	accountActivationPage: {
		metaTitle: 'Konto aktivieren',
	},
	country: {
		afg: 'Afghanistan',
		ala: 'Åland-Inseln',
		alb: 'Albanien',
		dza: 'Algerien',
		asm: 'Amerikanisch-Samoa',
		and: 'Andorra',
		ago: 'FAngola',
		aia: 'Anguilla',
		ata: 'Antarktis',
		atg: 'Fehlende Übersetzung',
		arg: 'Argentinien',
		arm: 'Armenien',
		abw: 'Aruba',
		aus: 'Australien',
		aut: 'Österreich',
		aze: 'Aserbaidschan',
		bhs: 'Bahamas',
		bhr: 'Bahrain',
		bgd: 'Bangladesch',
		brb: 'Barbados',
		blr: 'Weißrussland',
		bel: 'Belgien',
		blz: 'Belize',
		ben: 'Benin',
		bmu: 'Bermuda',
		btn: 'Bhutan',
		bol: 'Bolivien',
		bih: 'Bosnien und Herzegowina',
		bwa: 'Botswana',
		bvt: 'Bouvetinsel',
		bra: 'Brasilien',
		vgb: 'Jungferninseln',
		iot: 'Britisches Territorium im Indischen Ozean',
		brn: 'Brunei Darussalam',
		bgr: 'Bulgarien',
		bfa: 'Burkina Faso',
		bdi: 'Burundi',
		khm: 'Kambodscha',
		cmr: 'Kamerun',
		can: 'Kanada',
		cpv: 'Cabo Verde',
		cym: 'Cayman-Inseln',
		caf: 'Zentralafrikanische Republik',
		tcd: 'Tschad',
		chl: 'Chile',
		chn: 'China',
		hkg: 'Hongkong',
		mac: 'Macao',
		cxr: 'Weihnachtsinsel',
		cck: 'Cocos (Keeling) Inseln',
		col: 'Kolumbien',
		com: 'Komoren',
		cog: 'Kongo',
		cod: 'Die Demokratische Republik Kongo',
		cok: 'Cookinseln',
		cri: 'Costa Rica',
		civ: 'Côte d\'Ivoire',
		hrv: 'Kroatien',
		cub: 'Kuba',
		cyp: 'Zypern',
		cze: 'Tschechische Republik',
		dnk: 'Dänemark',
		dji: 'Dschibuti',
		dma: 'Dominica',
		dom: 'Dominikanische Republik',
		ecu: 'Ecuador',
		egy: 'Ägypten',
		slv: 'El Salvador',
		gnq: 'Äquatorial-Guinea',
		eri: 'Eritrea',
		est: 'Estland',
		eth: 'Äthiopien',
		flk: 'Falklandinseln',
		fro: 'Färöer-Inseln',
		fji: 'Fidschi',
		fin: 'Finnland',
		fra: 'Frankreich',
		guf: 'Französisch-Guayana',
		pyf: 'Französisch-Polynesien',
		atf: 'Französische Südterritorien',
		gab: 'Gabun',
		gmb: 'Gambia',
		geo: 'Georgien',
		deu: 'Deutschland',
		gha: 'Ghana',
		gib: 'Gibraltar',
		grc: 'Griechenland',
		grl: 'Grönland',
		grd: 'Grenada',
		glp: 'Guadeloupe',
		gum: 'Guam',
		gtm: 'Guatemala',
		ggy: 'Guernsey',
		gin: 'Guinea',
		gnb: 'Guinea-Bissau',
		guy: 'Guyana',
		hti: 'Haiti',
		hmd: 'Heard-Insel und McDonald-Inseln',
		vat: 'Heiliger Stuhl',
		hnd: 'Honduras',
		hun: 'Ungarn',
		isl: 'Island',
		ind: 'Indien',
		idn: 'Indonesien',
		irn: 'Iran',
		irq: 'Irak',
		irl: 'Irland',
		imn: 'Isle of Man',
		isr: 'Israel',
		ita: 'Italien',
		jam: 'Jamaika',
		jpn: 'Japan',
		jey: 'Jersey',
		jor: 'Jordanien',
		kaz: 'Kasachstan',
		ken: 'Kenia',
		kir: 'Kiribati',
		prk: 'Demokratische Volksrepublik Korea',
		kor: 'Die Republik Korea',
		kwt: 'Kuwait',
		kgz: 'Kirgisistan',
		lao: 'Demokratische Volksrepublik Laos',
		lva: 'Lettland',
		lbn: 'Libanon',
		lso: 'Lesotho',
		lbr: 'Liberia',
		lby: 'Libyen',
		lie: 'Liechtenstein',
		ltu: 'Litauen',
		lux: 'Luxemburg',
		mkd: 'Republik Nordmazedonien',
		mdg: 'Madagaskar',
		mwi: 'Malawi',
		mys: 'Malaysia',
		mdv: 'Malediven',
		mli: 'Mali',
		mlt: 'Malta',
		mhl: 'Marshallinseln',
		mtq: 'Martinique',
		mrt: 'Mauretanien',
		mus: 'Mauritius',
		myt: 'Mayotte',
		mex: 'Mexiko',
		fsm: 'Mikronesien',
		mda: 'Moldawien',
		mco: 'Monaco',
		mng: 'Mongolei',
		mne: 'Montenegro',
		msr: 'Montserrat',
		mar: 'Marokko',
		moz: 'Mosambik',
		mmr: 'Myanmar',
		nam: 'Namibia',
		nru: 'Nauru',
		npl: 'Nepal',
		nld: 'Niederlande',
		ant: 'Niederländische Antillen',
		ncl: 'Neukaledonien',
		nzl: 'Neuseeland',
		nic: 'Nicaragua',
		ner: 'Niger',
		nga: 'Nigeria',
		niu: 'Niue',
		nfk: 'Norfolkinsel',
		mnp: 'Nördliche Marianen',
		nor: 'Norwegen',
		omn: 'Oman',
		pak: 'Palau',
		plw: 'Pakistan',
		pse: 'Palästina',
		pan: 'Panama',
		png: 'Papua-Neuguinea',
		pry: 'Paraguay',
		per: 'Peru',
		phl: 'Pgilippinen',
		pcn: 'Pitcairn',
		pol: 'Polen',
		prt: 'Portugal',
		pri: 'Puerto Rico',
		qat: 'Katar',
		reu: 'Réunion',
		rou: 'Rumänien',
		rus: 'Russische Föderation',
		rwa: 'Ruanda',
		blm: 'St. Barthélemy',
		shn: 'St. Helena, Ascension und Tristan da Cunha',
		kna: 'St. Kitts und Nevis',
		lca: 'St. Lucia',
		maf: 'St. Martin',
		spm: 'St. Pierre und Miquelon',
		vct: 'St. Vincent und die Grenadinen',
		wsm: 'Samoa',
		smr: 'San Marino',
		stp: 'Sao Tome und Principe',
		sau: 'Saudi-Arabien',
		sen: 'Senegal',
		srb: 'Serbien',
		syc: 'Seychellen',
		sle: 'Sierra Leone',
		sgp: 'Singapur',
		svk: 'Slowakei',
		svn: 'Slowenien',
		slb: 'Salomonen',
		som: 'Somalia',
		zaf: 'Südafrika',
		sgs: 'Südgeorgien und die Südlichen Sandwichinseln',
		ssd: 'Südsudan',
		esp: 'Spanien',
		lka: 'Sri Lanka',
		sdn: 'Sudan',
		sur: 'Suirname',
		sjm: 'Svalbard und Jan Mayen',
		swz: 'Eswatini',
		swe: 'Schweden',
		che: 'Schweiz',
		syr: 'Syrien',
		twn: 'Taiwan',
		tjk: 'Tadschikistan',
		tza: 'Tansania',
		tha: 'Thailand',
		tls: 'Timor-Leste',
		tgo: 'Togo',
		tkl: 'Tokelau',
		ton: 'Tonga',
		tto: 'Trinidad und Tobago',
		tun: 'Tunesien',
		tur: 'Türkei',
		tkm: 'Turkmenistan',
		tca: 'Turks- und Caicosinseln',
		tuv: 'Tuvalu',
		uga: 'Uganda',
		ukr: 'Ukraine',
		are: 'Vereinigte Arabische Emirate',
		gbr: 'Vereinigtes Königreich von Großbritannien und Nordirland',
		usa: 'Vereinigte Staaten von Amerika',
		umi: 'Vereinigte Staaten Minor Outlying Islands',
		ury: 'Uruguay',
		uzb: 'Usbekistan',
		vut: 'Vanuatu',
		ven: 'Venezuela',
		vnm: 'Vietnam',
		vir: 'Jungferninseln',
		wlf: 'Wallis und Futuna',
		esh: 'Westsahara',
		yem: 'Jemen',
		zmb: 'Sambia',
		zwe: 'Simbabwe',
		// xkx: 'Missing translation',  Note: Kosovo is not listed as an ISO standard country. The unofficial 2 and 3-digit codes are used by the European Commission and others until Kosovo is assigned an ISO code.
	},
	participantOnboardingPage: {
		metaTitle: 'Onboarding',
		forms: {
			onboarding: {
				title: { normalTitle: 'Details ', coloredTitle: 'zum Unternehmen' },
				companyName: {
					label: 'Name des Unternehmens',
					placeholder: 'Aquga',
				},
				vatId: {
					label: 'Steuernummer',
					placeholder: '321456987',
				},
				industries: {
					automotive: 'Automotive',
					mechanical_electrical_engineering: 'Maschinenbau/Elektrotechnik',
					chemistry_pharmaceuticals: 'Chemie/Pharma',
					consumer: 'Consumer',
					trade_retail: 'Handel/Retail',
					logistics: 'Logistik',
					service: 'Dienstleistung',
					banking_insurance: 'Banken-/Versicherungen',
					energy: 'Energie',
					aerospace_and_defense: 'Luft-, Raumfahrt und Defense',
				},
				buttonText: 'Zu Adressdetails gehen',
				industryField: 'Branche wählen',
			},
			companyLocation: {
				title: { normalTitle: 'Details zu ', coloredTitle: 'Ihrem Unternehmen' },
				city: {
					label: 'Stadt',
					placeholder: 'Leipzig',
				},
				zipCode: {
					label: 'Postleitzahl',
					placeholder: '12345',
				},
				streetName: {
					label: 'Straße',
					placeholder: 'Kronenplatz',
				},
				houseNumber: {
					label: 'Hausnummer',
					placeholder: '32',
				},
				addressLine2: {
					label: 'Addressezusatz',
					placeholder: 'Optional',
				},
				buttonText: 'Kontaktdetails',
				country: 'Land auswählen',
			},
		},
	},
	participantRejectedPage: {
		meta: 'Sie haben keine Berechtigung zum Zugriff auf die Plattform - AQUGA',
		title: 'Sie haben nicht alle Voraussetzungen erfüllt, um Zugang zu AQUGA zu erhalten',
		body: 'Wenn Sie glauben, dass dies ein Fehler ist, melden Sie sich bitte per E-Mail',
	},
	participantAccount: {
		companyInfo: {
			title: 'Infos zum Unternehmen',
		},
		paymentDetailsSection: {
			title: 'Einzelheiten zur Zahlung',
			vbanDetails: {
				type: { title: 'Typ' },
				iban: { title: 'IBAN' },
				bic: { title: 'BIC' },
				country: { title: 'Land' },
				sepa: { title: 'SEPA-Überweisungen' },
				price: { title: 'Preis' },
				amount: { title: 'Gesamtbetrag' },
				tax: { title: 'Steuer' },
				reference: { title: 'Ref.nummer' },
			},
		},
	},
};
export default messages_de;
