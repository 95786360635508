import { findFieldByTechName } from '@aquga/app/fieldHelpers';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface AgreedDataViewProps {
	matchFields: any;
	price: number;
	loading: boolean;
}

export const AgreedDataView = ({ matchFields, price, loading }: AgreedDataViewProps) => {
	const intl = useIntl();
	const currentLocale = useAppSelector(selectCurrentLanguage);
	const agreedDescription = findFieldByTechName(
		matchFields,
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_DESCRIPTION
	)?.value;
	const priceFromMatch = findFieldByTechName(matchFields, RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_PRICE)
		?.value?.amount;

	const agreedPrice = priceFromMatch
		? new Intl.NumberFormat(currentLocale, { style: 'currency', currency: 'EUR' }).format(priceFromMatch / 100)
		: new Intl.NumberFormat(currentLocale, { style: 'currency', currency: 'EUR' }).format(price);

	return priceFromMatch || agreedDescription ? (
		<>
			<Stack direction="column" justifyContent="flex-start" spacing={1}>
				<Typography variant="overline">
					{intl.formatMessage({ id: 'questions.details.agreed_data_view.title' })}
				</Typography>
				{loading ? (
					<Skeleton width={120} />
				) : (
					agreedDescription && (
						<Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
							{agreedDescription[agreedDescription.length - 1]}
						</Typography>
					)
				)}
				{loading ? (
					<Skeleton width={120} />
				) : (
					<Typography variant="body1">
						{intl.formatMessage({ id: 'questions.details.agreed_data_view.price' })}
						{agreedPrice ?? price}
					</Typography>
				)}
			</Stack>
		</>
	) : (
		<></>
	);
};
