import { Transfer, TransferStatusKind } from '@aquga/services/randevuApi/generatedTypes';
import { useAppSelector } from '@aquga/store/configureStore';
import { selectCurrentLanguage } from '@aquga/store/slices/uiSlice';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { FormattedMessage, useIntl } from 'react-intl';

const TableCell = ({ value }: { value: string | undefined | null }) => {
	if (!value) return <Typography>-</Typography>;

	return <Typography variant="body2">{value}</Typography>;
};

const determineStatusChipColorAndLabelFromStatus = (status: TransferStatusKind) => {
	switch (status) {
	case TransferStatusKind.Created:
		return {
			label: <FormattedMessage id="participantAccountPage.finances.transfer.table.transferStatus.created" />,
			color: 'primary',
		};
	case TransferStatusKind.Failed:
		return {
			label: <FormattedMessage id="participantAccountPage.finances.transfer.table.transferStatus.failed" />,
			color: 'error',
		};

	case TransferStatusKind.Succeeded:
		return {
			label: (
				<FormattedMessage id="participantAccountPage.finances.transfer.table.transferStatus.succeeded" />
			),
			color: 'success',
		};
	}

	return { label: 'Succeeded', color: 'success' };
};

interface TransferStatusChipProps {
	status: TransferStatusKind;
}

const TransferStatusChip = ({ status }: TransferStatusChipProps) => {
	const chipProps = determineStatusChipColorAndLabelFromStatus(status);
	return <Chip color={chipProps.color as any} label={chipProps.label} />;
};

const NoDataView = () => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
			<Typography variant="subtitle2" sx={{ mt: 2 }}>
				<FormattedMessage id="participantAccountPage.finances.transfer.table.emptyStateText" />
			</Typography>
		</Stack>
	);
};

interface TransfersTableProps {
	transfers: any;
	loading: boolean;
	disabled: boolean;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	onOpenTransferDetailsDialog: (id: string) => void;
	onNextPage: () => void;
	onPreviousPage: () => void;
}

export const buildTransfersTableRows = (transfers: Transfer[]): any[] => {
	const rows = transfers?.map((transfer) => {
		return {
			_sent: { value: transfer?.succeeded_at },
			_amount: {
				value: transfer?.amount?.amount,
			},
			_status: { value: transfer?.status },
			_orderId: { value: transfer?.order?.id },
			_details: { value: transfer?.id },
		};
	});

	return rows;
};

const TransfersTable = ({
	transfers = [],
	hasNextPage,
	hasPreviousPage,
	loading,
	disabled,
	onNextPage,
	onPreviousPage,
	onOpenTransferDetailsDialog,
}: TransfersTableProps) => {
	const intl = useIntl();
	const locale = useAppSelector(selectCurrentLanguage);

	const columns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_sent',
			header: intl.formatMessage({ id: 'participantAccountPage.finances.transfer.table.columns.sent' }),
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				const formattedDate = data?.value
					? new Intl.DateTimeFormat(locale, { timeStyle: 'short', dateStyle: 'short' }).format(
						new Date(data?.value)
					  )
					: null;
				return <TableCell value={formattedDate} />;
			},
		},
		{
			accessorKey: '_amount',
			header: intl.formatMessage({ id: 'participantAccountPage.finances.transfer.table.columns.amount' }),
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return (
					<TableCell
						value={Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(
							data.value / 100
						)}
					/>
				);
			},
		},
		{
			accessorKey: '_status',
			header: intl.formatMessage({ id: 'participantAccountPage.finances.transfer.table.columns.status' }),
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return <TransferStatusChip status={data.value} />;
			},
		},
		{
			accessorKey: '_orderId',
			header: intl.formatMessage({ id: 'participantAccountPage.finances.transfer.table.columns.orderId' }),
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return <TableCell value={data.value} />;
			},
		},
		{
			accessorKey: '_details',
			header: '',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return (
					<IconButton onClick={() => onOpenTransferDetailsDialog(data.value as string)}>
						<ArrowForwardIcon />
					</IconButton>
				);
			},
		},
	];

	const transferRows = buildTransfersTableRows(transfers);

	return (
		<>
			<MaterialReactTable
				initialState={{
					pagination: { pageSize: 20, pageIndex: 0 },
				}}
				state={{
					isLoading: loading,
				}}
				columns={columns}
				enablePinning={false}
				data={transferRows}
				enableSorting={false}
				enablePagination={true}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				enableColumnActions={false}
				rowCount={transfers?.length}
				muiTableBodyProps={{
					children: transfers?.length < 1 ? <NoDataView /> : null,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: '#dde1e2',
						},
					},
				}}
				enableFullScreenToggle={false}
				enableRowOrdering={false}
				enableHiding={false}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: '#f1f3f3',
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				muiTablePaginationProps={{
					rowsPerPageOptions: [20],
					showFirstButton: false,
					showLastButton: false,
					backIconButtonProps: {
						disabled: !hasPreviousPage || loading,
						onClick: onPreviousPage,
					},
					nextIconButtonProps: {
						disabled: !hasNextPage || loading,
						onClick: onNextPage,
					},
				}}
			/>
		</>
	);
};

export default TransfersTable;
