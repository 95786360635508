import {
	MutationRequestMerchantAccountConnectionArgs,
	Payment,
	MutationRequestPayoutArgs,
	MutationRequestStripeDashboardSignInLinkArgs,
	QueryMyTransfersArgs,
	TransferConnection,
	MutationOpenVirtualBankAccountArgs,
	MutationPrepareTransactionPaymentArgs,
	MutationRequestBankTransferCheckoutArgs,
} from '@aquga/services/randevuApi/generatedTypes';

import * as paymentMutations from './paymentMutations';
import * as paymentQueries from './paymentQueries';

class PaymentsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	/**
	 * Allows provider to connect its randevu account (participant account) to an merchant account at given PSP
	 * E.g. Provider gets URL to connect its randevu account to its Stripe account (if no Stripe account is available, provider will have to create one)
	 * E.g. Provider gets URL to connect its randevu account to its PayPal account (if no PayPal account is available, provider will have to create one)
	 * @param
	 * @returns URL to create/update an merchant account
	 */
	async requestMerchantAccountConnection({
		id_integration_provider,
		refresh_url,
		return_url,
	}: MutationRequestMerchantAccountConnectionArgs): Promise<{ url: Payment | null; errors: any }> {
		const payload = paymentMutations.requestMerchantAccountConnection({
			id_integration_provider,
			refresh_url,
			return_url,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { url: data?.requestMerchantAccountConnection?.url, errors };
	}

	/**
	 * Composed method to allow provider to connect its randevu account to its Stripe Account
	 * @param
	 * @returns URL to Stripe
	 */
	async requestStripeConnectAccountConnection({
		id_integration_provider,
		refresh_url,
		return_url,
	}: MutationRequestMerchantAccountConnectionArgs): Promise<{ url: any | null; errors: any }> {
		return await this.requestMerchantAccountConnection({
			id_integration_provider,
			refresh_url,
			return_url,
		});
	}

	async myTransfers({
		where,
		after,
		before,
		first,
		last,
	}: QueryMyTransfersArgs): Promise<{ transfer_connection: TransferConnection; errors: any }> {
		const payload = paymentQueries.myTransfers({ where, after, before, first, last });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { transfer_connection: data?.myTransfers, errors };
	}

	async requestPayout({
		id_merchant_account,
	}: MutationRequestPayoutArgs): Promise<{ requested: boolean; errors: any }> {
		const payload = paymentMutations.requestPayout({ id_merchant_account });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { requested: data?.requestPayout, errors };
	}

	async prepareTransactionPayment({
		id_transaction,
		tech_name,
	}: MutationPrepareTransactionPaymentArgs): Promise<{ payment: Payment; errors: any }> {
		const payload = paymentMutations.prepareTransactionPayment({
			id_transaction,
			tech_name,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { payment: data?.prepareTransactionPayment, errors };
	}
	async requestBankTransferCheckout({
		id_payment,
	}: MutationRequestBankTransferCheckoutArgs): Promise<{ payment: Payment; errors: any }> {
		const payload = paymentMutations.requestBankTransferCheckout({
			id_payment,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { payment: data?.requestBankTransferCheckout, errors };
	}
	async requestStripeDashboardSignInLink({
		id_merchant_account,
	}: MutationRequestStripeDashboardSignInLinkArgs): Promise<{ url: string; errors: any }> {
		const payload = paymentMutations.requestStripeDashboardSignInLink({ id_merchant_account });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { url: data?.requestStripeDashboardSignInLink, errors };
	}
	async openVirtualBankAccount({
		id_integration_provider,
		id_participant,
		country_code,
		currency,
	}: MutationOpenVirtualBankAccountArgs): Promise<{ success: boolean; errors: any }> {
		const payload = paymentMutations.openVirtualBankAccount({
			id_integration_provider,
			id_participant,
			currency,
			country_code,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { success: Boolean(data?.openVirtualBankAccount), errors };
	}
}

export default PaymentsService;
