import { findFieldByTechName, findSupplyFilterByTechName } from '@aquga/app/fieldHelpers';
import { CreateQuestionFormValues } from '@aquga/components/questions/create/CreateQuestionForm';
import { RANDEVU_IDS } from '@aquga/config/randevuIds';
import { Field, FieldFilter, Transaction, TransactionLight } from '@aquga/services/randevuApi/generatedTypes';

interface TransactionDetails {
	id?: string;
	title: string;
	description: string;
	price: number;
	agreedPrice: number;
	createdAt: string;
	internal_ref_ticket: string;
	priority: string;
	dueDate: string;
	providerName: string;
	consumerName: string;
	messages: string[];
	ticketNumber: number;
	additionalNote: string;
	additionalDocs: { id_file: string; description?: string; file_name: string; name?: string; url: string }[];
	topics: string[];
	topicsOther: string;
	expertiseOther: string;
	services: string[];
	status: string;
	subTransactions: any; //FIXME: add type
}

export const mapTransactionDetails = (transaction: Transaction | null): TransactionDetails => {
	const matchingTool = transaction?.order;

	const fieldTitle = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.TITLE.TECH_NAME
	);
	const fieldDescription = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.DESCRIPTION.TECH_NAME
	);
	const fieldInternalRefTicket = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.INTERNAL_REF_TICKET.TECH_NAME
	);
	const fieldPriority = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.PRIORITY.TECH_NAME
	);
	const fieldDueDate = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.DUE_DATE.TECH_NAME
	);
	const fieldAdditionalNote = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_NOTES.TECH_NAME
	);
	const fieldAdditionalDocs = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
	);
	const ticketNumber = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.TICKET_NUMBER.TECH_NAME
	)?.value;

	const fieldPrice = findFieldByTechName(
		matchingTool?.fields as Field[],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.PRICE.TECH_NAME
	);
	const fieldAgreedPrice = findFieldByTechName(
		transaction?.matches?.[0]?.fields as Field[],
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_PRICE
	);
	const agreedDescription = findFieldByTechName(
		transaction?.matches?.[0]?.fields as Field[],
		RANDEVU_IDS.TICKET_CONSULTANT_MATCH.FIELDS.AGREED_DESCRIPTION
	)?.value;
	const priceAmount = fieldPrice?.value?.amount;
	const agreedPriceAmount = fieldAgreedPrice?.value?.amount;

	const filterFieldExpertise = findSupplyFilterByTechName(
		matchingTool?.supply_filter as FieldFilter[],
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.TECH_NAME
	);
	const filterFieldTopics = findSupplyFilterByTechName(
		matchingTool?.supply_filter as FieldFilter[],
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.TECH_NAME
	);
	const fieldOtherTopics = findSupplyFilterByTechName(
		matchingTool?.supply_filter as FieldFilter[],
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KIND_OTHER.TECH_NAME
	)?.value?.values;
	const fieldOtherExpertise = findSupplyFilterByTechName(
		matchingTool?.supply_filter as FieldFilter[],
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA_OTHER.TECH_NAME
	)?.value?.values;

	const fieldProviderFirstName = findFieldByTechName(
		transaction?.matches?.[0]?.provider_fields as Field[],
		'first_name'
	)?.value;
	const fieldProviderLastName = findFieldByTechName(
		transaction?.matches?.[0]?.provider_fields as Field[],
		'last_name'
	)?.value;
	const fieldConsumerFirstName = findFieldByTechName(
		transaction?.matches?.[0]?.consumer_fields as Field[],
		'first_name'
	)?.value;
	const fieldConsumerLastName = findFieldByTechName(
		transaction?.matches?.[0]?.consumer_fields as Field[],
		'last_name'
	)?.value;

	const subTransactions =
		transaction?.sub_transactions?.map((subtransction: TransactionLight) => {
			return {
				id: subtransction.id,
				createdAt: subtransction.created_at,
				status: subtransction.state?.status_tech_name,
				providerFields: subtransction.provider_fields && [...subtransction.provider_fields],
			};
		}) ?? [];

	const question = {
		id: transaction?.id,
		title: fieldTitle?.value,
		description: fieldDescription?.value,
		price: priceAmount / 100,
		agreedPrice: agreedPriceAmount ? agreedPriceAmount / 100 : priceAmount / 100,
		createdAt: transaction?.created_at,
		internal_ref_ticket: fieldInternalRefTicket?.value,
		priority: fieldPriority?.value,
		dueDate: fieldDueDate?.value,
		additionalNote: fieldAdditionalNote?.value,
		additionalDocs: fieldAdditionalDocs?.value,
		messages: agreedDescription,
		topics: filterFieldTopics?.value?.values,
		topicsOther: fieldOtherTopics,
		expertiseOther: fieldOtherExpertise,
		services: filterFieldExpertise?.value?.values,
		status: transaction?.state?.status_tech_name ?? '',
		subTransactions: subTransactions,
		ticketNumber: ticketNumber,
		consumerName: `${fieldConsumerFirstName} ${fieldConsumerLastName}`,
		providerName: `${fieldProviderFirstName} ${fieldProviderLastName}`,
	};

	return question;
};

export const mapTransactionToTicketOverview = (transaction: TransactionLight) => {
	const fieldTitle = findFieldByTechName(
		transaction?.order_fields ?? [],
		RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.TITLE.TECH_NAME
	);

	const ticketOverview = {
		id: transaction.id,
		title: fieldTitle?.value,
		createdAt: transaction.created_at,
		toBeAnsweredUntil:
			findFieldByTechName(
				transaction?.order_fields ?? [],
				RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.DUE_DATE.TECH_NAME
			)?.value || 'ASAP',
		terminatedAt: transaction.terminated_at,
		value: findFieldByTechName(
			transaction?.order_fields ?? [],
			RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.PRICE.TECH_NAME
		)?.value,
		matches: transaction.sub_transactions_count,
		status: transaction?.state?.status_tech_name ?? 'Hard coded Unknown',
	};

	return ticketOverview;
};

export const mapCreateTicketFormValuesToSupplyFilters = (formValues: CreateQuestionFormValues): any => {
	const allowedFilters = [
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA.TECH_NAME,
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.BUSINESS_AREA_OTHER.TECH_NAME,
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KINDS.TECH_NAME,
		RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS.TECHNOLOGY_KIND_OTHER.TECH_NAME,
	];
	const fieldTypes = Object.entries(RANDEVU_IDS.SUPPLY_TYPE_CONSULTING_SERVICE.FIELDS).map(
		(fieldTypeMap) => fieldTypeMap[1]
	);

	return Object.entries(formValues)
		.map(([fieldName, fieldValue]) => {
			const fieldTechName = fieldTypes.find(
				(field) => field.TECH_NAME === fieldName && allowedFilters.includes(field.TECH_NAME)
			)?.TECH_NAME;
			return {
				tech_name: fieldTechName,
				value: typeof fieldValue === 'string' ? fieldValue : { values: fieldValue, min_hits: 1 },
			};
		})
		.filter((fieldTypeValue) => fieldTypeValue.tech_name !== undefined);
};

export const mapCreateTicketFormValuesToMatchingOrderFields = (formValues: CreateQuestionFormValues): any => {
	const fieldTypes = Object.entries(RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS).map(
		(fieldTypeMap) => fieldTypeMap[1]
	);

	return Object.entries(formValues)
		.map(([fieldName, value]) => {
			const idFieldType = fieldTypes.find((field) => field.TECH_NAME === fieldName)?.TECH_NAME;

			if (RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.PRICE.TECH_NAME === fieldName) {
				value = {
					amount: Number(value * 100),
					unit: 'CENT',
					currency: 'EUR',
				};
			}

			return {
				tech_name: idFieldType,
				value: value,
			};
		})
		.filter((fieldValue) => fieldValue.tech_name !== undefined)
		.filter(
			(fieldValue) =>
				fieldValue.tech_name !== RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
		);
};

export const mapCreateTicketFormValuesAdditionDocumentsFormFieldRandevuField = (
	formValues: CreateQuestionFormValues
): undefined | { tech_name: any; value: any } => {
	const fieldTypes = Object.entries(RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS).map(
		(fieldTypeMap) => fieldTypeMap[1]
	);

	const foundField = Object.entries(formValues)
		.map(([fieldName, value]) => {
			const idFieldType = fieldTypes.find((field) => field.TECH_NAME === fieldName)?.TECH_NAME;

			return {
				tech_name: idFieldType,
				value: value,
			};
		})
		.filter(
			(fieldValue) =>
				fieldValue.tech_name === RANDEVU_IDS.TICKET_MATCHING_ORDER_TYPE.FIELDS.ADDITIONAL_DOCS.TECH_NAME
		);

	if (foundField) return foundField[0];

	return undefined;
};
