const messages_en = {
	common: {
		onboardingStepper: 'Step',
		btnSignIn: 'Log in',
		btnSignOut: 'Log out',
		withdraw: 'Withdraw',
		btnAskQuestions: 'Ask questions',
		btnAnswerQuestions: 'Answer questions',
		btnAddAnsweringExpertise: 'Add answering expertise',
		termsOfService: 'Terms of Service',
		privacyPolicy: 'Privacy Policy',
		cookiePolicy: 'Cookie Policy',
		sourcedTalentMatchingAgreement: 'Sourced Talent Matching Agreement',
		back: 'Back',
		open: 'Open',
		cancel: 'Cancel',
		save: 'Save',
		account: 'Account',
		and: 'and',
		comingSoon: 'Coming soon...',
		dropZone: {
			title: 'Drop here or browse files',
			fileFormat: 'File format: {fileFormat}',
			fileSize: 'Max. file size: {maxFileSize} mb',
			maxFiles: 'Max. {maxFiles} files',
			release: 'Release!',
			generalErrors: 'Invalid document(s) detected!',
		},
	},
	pointOfContact: {
		title: 'Point of Contact',
	},
	personalInfo: {
		title: 'Personal Info',
	},
	participantAccountPage: {
		metaTitle: 'Your account',

		title: 'Account',
		tabs: {
			general: 'General',
			finances: 'Finances',
		},
		finances: {
			merchantAccount: {
				linkAccounts: {
					linkBtn: 'Link accounts',
					bannerText:
						'For you to receive the money from our side, you must link your account with a merchant account at Stripe.',
					bannerTitle: 'Please link your merchant account with Stripe',
				},
				continueLinkingAccounts: {
					continueBtn: 'Go to Stripe',
					bannerText:
						'Stripe cannot finish onboarding you because it requires more information from you. Please go to Stripe to wrap up connecting with your account.',
					bannerTitle: 'Additional information required for your Stripe account',
				},
				linkedAccounts: {
					title: 'Merchant account',
					viewAccountBtn: 'View account details',
					requestPayoutBtn: 'Request payout',
				},
			},
			transfer: {
				detailsDialog: {
					title: 'Transfer details',
					gross: 'Gross amount',
					takerate: 'Platform commission',
					taxes: 'Taxes',
					earning: 'Net amount',
				},
				table: {
					columns: {
						sent: 'Sent',
						amount: 'Amount',
						status: 'Status',
						orderId: 'Order ID',
					},
					transferStatus: {
						created: 'Created',
						failed: 'Failed',
						succeeded: 'Succeeded',
					},
					emptyStateText: 'No transfers yet',
				},
			},
		},
	},
	navigation: {
		consumer: {
			tickets: 'Questions',
			createTickets: 'Ask questions',
			titleAsker: 'Asking',
			titleConsultant: 'Answering',
		},
		menu: 'Menu',
		dashboard: 'Dashboard',
		contracts: 'Contracts',
		productOwner: 'A product by ix3 GmbH',
		answeringExpertise: 'Answering expertise',
	},
	langs: {
		de: 'De',
		german: 'German',
		en: 'En',
		english: 'English',
		english_us: 'English (United States)',
	},
	footer: {
		contactUs: 'Contact Us',
		faq: 'Frequently Asked Questions (FAQ)',
		title: 'Ask questions get answers!',
		followUs: 'Follow us',
		copyRight: 'All rights reserved © 2022 - {currentYear} ix3 GmbH',
	},

	home: {
		hero: {
			titleFirstLine: 'We',
			titleSecondLine: 'deliver',
			titlePoints: ['specialists', 'value', 'expertise', 'transparency'],
			subTitle: 'Ask questions get answers!',
			explainer:
				'We believe a combination of industry expertise and functional knowledge is critical for a successful SAP implementation.',
		},
		usp: {
			eyebrow: 'The benefit',
			title: 'Title would go here',
			points: [
				'Faster time to solution',
				'Lower costs, Minimized Risks',
				'Higher success rate',
				'Greater transparency',
				'Less administrative effort',
			],
		},
		business: {
			eyebrow: 'Our Promised Value',
			title: 'Solutions with a big impact for your business',
			explainerPart01:
				'Today you pay the price based on time consumption. The price of the solution only becomes transparent at the end',
			explainerPart02: 'At',
			explainerPart03: 'you pay the price for the solution you find valuable!',
		},
		consultant: {
			eyebrow: 'Who is it for',
			title: 'Find the right challenge for you',
			explainerPart01:
				'If you are an experienced SAP consultant and want to provide high-quality SAP software development at',
			explainerPart02: 'for consulting services to global enterprises, join us!',
		},
		howItWorks: {
			eyebrow: 'How it works',
			title: 'How to get support from SAP experts',
			titlePoint1: 'Title goes here 1',
			explainerPoint1: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
			titlePoint2: 'Title goes here 2',
			explainerPoint2: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
			titlePoint3: 'Title goes here 3',
			explainerPoint3: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
		},
	},
	myAnsweringExpertise: {
		title: 'Answering Expertise',
	},
	reauthenticationDialog: {
		title: 'Your session has expired',
		body: 'If you want to continue, re-write your password.',
		password: {
			label: 'Password',
			placeholder: 'Enter your password',
			validation: { incorrectPassword: 'Provided password was incorrect' },
		},
		continueButton: 'Log in',
		cancelButton: 'Cancel',
	},
	languageDialog: {
		title: 'Select language',
	},
	services: {
		list: {
			noServicesAddedyet: 'You have no answering expertise yet',
		},
	},
	questions: {
		list: {
			title: 'Questions',
			update: 'Update',
			metaTitle: 'Your questions',
			noQuestionsAskedYet: 'You did not ask any questions yet',
			noQuestionsYet: 'There are no questions yet',
			filter: {
				all: 'All',
				active: 'Active',
				past: 'Past',
			},
			services: {
				expertise: 'Expertise',
				serviceProvided: 'Service provided',
				answeringExpertise: 'Answering Expertise',
			},

			questionCounter: {
				singular: 'Question',
				plural: 'Questions',
			},
			table: {
				columnQuestion: 'Question',
				columnToBeAnswered: 'To be answered until',
				value: 'Value',
				columnMatches: 'Matches',
				columnStatus: 'Status',
			},
		},
		create: {
			metaTitle: 'Add question',
			title: 'Add question',
			subtitle: 'Tell us your business needs so we can set you up with best matches',
			sectionTitleProblem: 'Help consultants understand your needs',
			sectionTitleBudget: 'What is your value?',
			sectionTitleTopics: 'What business focus area are you looking for?',
			sectionTitlePriority: 'When do you need the question to be solved by?',
			sectionTitleService: 'What technology are you using?',
			sectionTitleAdditionalInfo: 'Additional information',
			consultantSectionFiles:
				'Add all relevant documents (e.g. project profile, certificates, etc.) that describe your solution expertise.',
			businessSectionFiles: 'Add any relevant documents that further describe or specify your question',

			btnSubmitQuestion: 'Create question',
			btnSubmitAnsweringExpertise: 'Create expertise',
			breadcrumbs: {
				questions: 'Questions',
				addQuestion: 'Add question',
			},
		},
		details: {
			generic: {
				title: {
					status: 'STATUS',
					matchDate: 'MATCH DATE',
					technology: 'TECHNOLOGY',
					businessFocusArea: 'BUSINESS FOCUS AREA',
					additionalInformation: 'ADDITIONAL INFORMATION',
				},
			},
			metaTitle: 'Question',
			matches: {
				table: {
					columnProvider: 'Answerer',
					columnCreatedOn: 'Matched on',
					columnStatus: 'Status',
				},
			},
			sectionTitleMatches: 'Matches',
			explainerPoint1: 'No matches yet. Your question is waiting for approval.',
			sectionTitleDescription: 'Description',
			sectionTitleAdditionalNotes: 'Additional Information',
			tabTitleOverview: 'Overview',
			tabTitleDescription: 'Description',
			tabTitleMatches: 'Matches',
			dueDate: 'By {dueDate}',
			contract: {
				title: 'Contract',
				generating: 'Generating contract',
			},
			answer: {
				title: 'Answer',
			},
			meeting_proposed_view: {
				title: 'Meeting the business',
				btnProposeNewTime: 'Propose new time',
				btnSubmit: 'Accept meeting',
			},
			requested_changes_view: { title: 'Requested changes' },
			agreed_data_view: {
				title: 'Agreed data',
				description: 'Description: ',
				price: 'Price: ',
			},
			message_history_accordion: {
				title: 'History',
				roles: {
					you: 'You: ',
				},
			},
			meeting_scheduled_view: {
				btnRequestChanges: 'Request changes',
				btnSubmit: 'Confirm requirements',
			},
			uploadSolutionView: {
				title: 'Add the answer documents below',
				btnSubmit: 'Submit answer',
				previousAnswers: {
					title: 'Previous answer(s) submitted',
					requestedChanges: 'Requested changes',
				},
			},
			answerDeliveredView: {
				btnSubmit: 'Accept answer',
				btnRequestChanges: 'Request changes',
				requestedChanges: {
					title: 'Requested changes',
				},
			},
			contractOfferedView: {
				btnAccept: 'Accept & sign contract',
				btnDecline: 'Decline',
			},
			openOfferMatchOptions: {
				btnNotInterested: 'Not interested',
				btnRequestToJoin: 'Request to join',
			},
			contactDetailsView: {
				title: 'Contact details',
				phone: 'Contact number: ',
				email: 'Email: ',
				name: 'Contact person: ',
			},
		},
		match: {
			options: {
				btnDismiss: 'Dismiss match',
				btnDecideLater: 'Decide later',
				btnDecline: 'Decline',
				btnApprove: 'Approve',
			},
			contract: {
				title: 'Contract',
				contractOfferingView: {
					title: 'Add signed contract',
					btnSubmit: 'Sign contract',
				},
				matchTimeView: {
					title: 'Meet the respondent',
					body: 'Send a message to the respondent or schedule a meeting to get the answers you are looking for faster.',
					btnSendMessage: 'Send message',
					btnScheduleMeeting: 'Schedule meeting',
				},
				proposedTimeView: {
					title: 'Meeting the respondent',
					btnSendNewProposal: 'Send new proposal',
				},
				scheduledView: {
					title: 'Requirements',
					btnUpdateRequirements: 'Update requirements',
				},
			},
			answer: {
				title: 'Answer',
			},
			respondent: {
				title: 'Respondent',
			},
		},
		dialogs: {
			updateRequirementsDialog: {
				common: {
					title: 'Update requirements',
					body: 'What updates you want to have on this question requirements?',
					explainer:
						'Try to be as specific as possible. This will ensure the updates sent can be understood faster.',
					btnCancel: 'Cancel',
					fields: {
						message: {
							label: 'Description',
							placeholder: 'Add a personalized message',
						},
					},
				},
				consumer: {
					btnSubmit: 'Send updates',
				},
				provider: {
					btnSubmit: 'Request updates',
				},
			},
			declineContractDialog: {
				title: 'Decline contract',
				body: 'Are you sure you want to decline this contract?',
				explainer: 'By doing this, you will no longer be able to work with this business.',
				btnCancel: 'Cancel',
				btnSubmit: 'Yes, I\'m sure',
				fields: {
					message: {
						label: 'Reason to decline',
						placeholder: 'Add your reason(s) to decline the contract',
					},
				},
			},
			requestChangeDialog: {
				title: 'Request change',
				body: 'What improvements you want to have on the answer provided?',
				explainer:
					'Try to be as specific as possible. This will ensure the improvements can be made faster and inline with your needs.',
				btnCancel: 'Cancel',
				btnSubmit: 'Send request',
				fields: {
					improvementNotes: {
						label: 'Improvement notes',
						placeholder: 'Add your improvement notes',
					},
				},
			},
			scheduleMeetingDialog: {
				title: 'Schedule meeting',
				body: 'Schedule below a meeting proposal with your matched respondent',
				btnSubmit: 'Send meeting proposal',
				btnCancel: 'Cancel',
				form: {
					date: {
						label: 'Date',
					},
					time: {
						from: 'From',
						to: 'To',
					},
				},
			},
		},
	},
	termsOfService: {
		title: 'Terms of Service',
	},
	privacyPolicy: {
		title: 'Privacy Policy',
	},
	sourcedTalentMatchingAgreement: {
		title: 'Sourced Talent Matching Agreement',
	},
	cookiePolicy: {
		title: 'Cookie Policy',
	},
	registerPage: {
		businessSide: {
			metaTitle: 'Register as business and get your questions answered',
			signUpAsOtherSide: 'Are you answering questions?',
			title: { normal: 'Create an account to', colored: 'ask questions' },
		},
		consultingSide: {
			metaTitle: 'Register as consultant and earn by answering questions',
			signUpAsOtherSide: 'Are you asking questions?',
			title: {
				normal: 'Create an account to',
				colored: 'answer questions',
			},
		},
		emailSentView: {
			title: { normal: 'Check your', colored: 'email' },
			subtitle: 'Almost done!',
			explainer:
				'We have sent an email with a confirmation link. Click on the link to activate your account. Remember to double check your spam',
		},
		form: {
			agreement: 'By signing up you agree to AQUGA\'s ',
			fields: {
				email: { label: 'Your company email', placeholder: 'elias.schneider@company.com' },
				firstName: { label: 'First name', placeholder: 'Elias' },
				lastName: { label: 'Last name', placeholder: 'Schneider' },
			},
			btnSignUp: 'Create an account',
		},
		activateAccount: {
			title: {
				normal: 'Account',
				colored: 'activation',
			},
			bulletPoint1: 'Between 9 and 64 characters',
			bulletPoint2: 'An uppercase character',
			bulletPoint3: 'A lowercase character',
			bulletPoint4: 'A number',
			bulletPoint5: 'A special character',
			passwordExplainer: 'Password must contain:',
			passwordLabel: 'Create password',
			passwordPlaceholder: 'password',
			buttonText: 'Go to company details',
		},
		accountReview: {
			title: {
				normal: 'We are reviewing',
				colored: 'your profile',
			},
			subtitle: 'Check your email',
			bulletPoint1: 'You will get an email when the reviewing process is done. It should not take long.',
			bulletPoint2: 'Remember to double check your spam.',
		},
		addContactDetails: {
			title: {
				normal: 'Your point of contact ',
				colored: 'details',
			},
			contactPerson: { label: 'Contact Person', placeholder: 'Elias Schneider' },
			email: { label: 'Company email', placeholder: 'elias.schneider@company.com' },
			phoneNumber: { label: 'Phone number', placeholder: '+49 30 567371152' },
			buttonText: 'Finish',
		},
	},
	resources: {
		files: {
			additionalDocs: {
				label: 'Additional documentation',
			},
		},
		service: {
			service: {
				label: 'Service Type',
				placeholder: '',
				values: {
					sales_crm: ' Sales/CRM',
					logistics: ' Logistics',
					financials: ' Financials',
					production_qm: ' Production/QM',
					purchasing: ' Purchasing',
					warehouse: ' Warehouse',
					planning: ' Planning',
					analytics: ' Analytics',
					hcm: ' HCM',
					other: ' Other',
				},
			},
			topics: {
				label: 'Expertise',
				placeholder: '',
				values: {
					sap: 'SAP',
					microsoft_erp: 'Microsoft ERP',
					robotics: 'Robotics & Automation',
					other: 'Other',
				},
			},
		},
		question: {
			service: {
				label: 'Service Type',
				placeholder: '',
				values: {
					sales_crm: ' Sales/CRM',
					logistics: ' Logistics',
					financials: ' Financials',
					production_qm: ' Production/QM',
					purchasing: ' Purchasing',
					warehouse: ' Warehouse',
					planning: ' Planning',
					analytics: ' Analytics',
					hcm: ' HCM',
					other: ' Other',
				},
			},
			topics: {
				label: 'Expertise',
				placeholder: '',
				values: {
					sap: 'SAP',
					microsoft_erp: 'Microsoft ERP',
					robotics: 'Robotics & Automation',
					other: 'Other',
				},
			},
			title: {
				label: 'Title',
				placeholder: 'Resume your question in a concise sentence',
			},
			description: {
				label: 'Description',
				placeholder: 'Contextualize your question as details as possible',
			},
			price: {
				label: 'Amount',
				label1: 'Value',
				placeholder: '3000',
				agreedPrice: 'Agreed value',
			},
			internalRefTicket: {
				label: 'Internal Reference Ticket Number',
				placeholder: 'Your internal reference ticket number in case you have it',
			},
			status: {
				label: 'Status',
				placeholder: '',
			},
			createdOn: {
				label: 'Created on',
				placeholder: '',
			},
			priority: {
				label: 'Time frame',
				placeholder: '',
				values: {
					asap: 'Asap',
					due_date: 'Due date',
				},
			},
			additionalNote: {
				label: 'Additional notes',
				placeholder: 'Add your notes',
			},
		},
		mainTransaction: {
			stateTypes: {
				open: 'Open',
				in_progress: 'In progress',
				solution_accepted_awaiting_payment: 'Requires payment',
				finished: 'Closed',
			},
		},
		subTransaction: {
			stateTypes: {
				suggested: 'Awaiting for approval',
				rejected: 'Not approved',
				auto_rejected: 'Rejected',
				opportunity_for_consultant: 'Open Offer',
				consultant_applied: 'Applied',
				declined: 'Not interested',
				application_parked: 'Decide later',
				application_dismissed_by_business: 'Not interested',
				match_time: 'It\'s a match',
				consultant_not_interested: 'Cancelled',
				meeting_time_proposed: 'Proposed time',
				meeting_invite: 'Meeting invitation',
				scheduled: 'Scheduled',
				changes_requested: 'Changes requested',
				changes_received: 'Changes received',
				contract_offering: 'Contract offering',
				contract_offered: 'Contract offered',
				consultant_withdrew: 'Cancelled',
				business_withdrew_contract: 'Contract withdrawn',
				contract_accepted: 'Contracted',
				answer_delivered: 'Answer delivered',
				improving_answer: 'Answer to be improved',
				answer_accepted: 'Answer accepted',
				payment_prepared: 'Awaiting payment',
				payment_captued: 'Paid',
			},
			answer: {
				stateTypes: {
					answering: {
						contract_accepted: 'Deliver answer',
					},
					asking: {
						contract_accepted: 'Awaiting answer',
					},
				},
			},
		},
	},
	updateAnsweringExpertisePage: {
		btnSubmit: 'Update Answering Expertise',
		metaTitle: 'Update Answering Expertise',
		title: 'Update Answering Expertise',
		updateAnsweringExpertise: 'Update Answering Expertise',
		moreInfo: 'Please input your desired updates',
	},
	createAnsweringExpertisePage: {
		metaTitle: 'Answering expertise',
		title: 'Answering Expertise',
		addAnsweringExpertise: 'Add Answering Expertise',
		moreInfo: 'Tell us the answering expertise you provide so we can set you up with the best question(s) matches.',
		form: {
			type: { label: 'What technology do you support? *' },
			expertise: { label: 'In which business focus area do you have answers? *' },
			understandBackground: { title: 'Help the asking person understand your background' },
			name: { label: 'Title', placeholder: 'Give your expertise a title' },
			description: { label: 'Description', placeholder: 'Contextualize your expertise as detailed as possible' },
			additionalNote: {
				title: 'Additional information',
				label: 'Additional note',
				placeholder: 'Add your notes',
			},
		},
	},
	chooseNewPasswordPage: {
		metaTitle: 'Choose new password',
		title: { normalTitle: 'Choose new ', coloredTitle: 'password' },
		fields: { password: { validation: { generalError: 'Please try again' } } },
	},
	forgotPasswordPage: {
		metaTitle: 'Forgot password',
		title: { normalTitle: 'Forgot ', coloredTitle: 'password' },
		btnRequestPasswordReset: 'Send reset link',
		bottomBarNormalText: 'Need help? Contact ',
		form: {
			email: { label: 'Your company email', placeholder: 'elias.schneider@company.com' },
		},
		forgotPasswordRequestedView: {
			title: { normalTitle: 'Reset link has been  ', coloredTitle: 'sent' },
			body: {
				title: 'Check your email',
				text: 'An email with your reset password link has been sent to',
				email: 'name.surname@company.com',
				linkExpTime: 'The link will expire in XXXX hour',
			},
			bottomBar: {
				text: 'Go back to',
				linkText: 'Log in',
			},
		},
	},
	loginPage: {
		metaTitle: 'Login',
		form: {
			title: { normalTitle: 'Log in to ', coloredTitle: 'AQUGA' },
			email: {
				label: 'Your company email',
				placeholder: 'elias.schneider@company.com',
				validation: {
					invalidEmail: 'Invalid email',
					incorrectEmailOrPassword: 'Your email or password are incorrect.',
				},
			},
			password: {
				label: 'Password',
				placeholder: 'Your password',
			},
			forgotPassword: 'Forgot password',
			buttonText: 'Log in',
		},
		bottomBar: {
			title: 'Don\'t have an account?',
			btnSignUpAsConsultant: 'Answer questions',
			btnSignUpAsBusiness: 'Ask questions',
		},
	},
	validation: {
		general: {
			invalidEmail: 'Invalid email',
			mandatoryField: 'Required',
			minLengthThreeCharacters: 'Must be at least 3 characters long',
			minLengthFiveCharacters: 'Must be at least 5 characters long',
			minLength: 'Please select at least one option',
			digitsOnly: 'Only digits are allowed',
			hourMinuteFormat: 'HH:MM format required',
			file: {
				fileTooLarge: 'File is too big.',
				tooManyFiles: 'More files provided than allowed',
				fileInvalidType: 'File format not allowed.',
			},
		},
		password: {},
	},
	accountActivationPage: {
		metaTitle: 'Activate account',
	},
	country: {
		afg: 'Afghanistan',
		ala: 'Åland Islands',
		alb: 'Albania',
		dza: 'Algeria',
		asm: 'American Samoa',
		and: 'Andorra',
		ago: 'Angola',
		aia: 'Anguilla',
		ata: 'Antarctica',
		atg: 'Antigua and Barbuda',
		arg: 'Argentina',
		arm: 'Armenia',
		abw: 'Aruba',
		aus: 'Australia',
		aut: 'Austria',
		aze: 'Azerbaijan',
		bhs: 'Bahamas',
		bhr: 'Bahrain',
		bgd: 'Bangladesh',
		brb: 'Barbados',
		blr: 'Belarus',
		bel: 'Belgium',
		blz: 'Belize',
		ben: 'Benin',
		bmu: 'Bermuda',
		btn: 'Bhutan',
		bol: 'Bolivia',
		bih: 'Bosnia and Herzegovina',
		bwa: 'Botswana',
		bvt: 'Bouvet Island',
		bra: 'Brazil',
		vgb: 'Virgin Islands',
		iot: 'British Indian Ocean Territory',
		brn: 'Brunei Darussalam',
		bgr: 'Bulgaria',
		bfa: 'Burkina Faso',
		bdi: 'Burundi',
		khm: 'Cambodia',
		cmr: 'Cameroon',
		can: 'Canada',
		cpv: 'Cabo Verde',
		cym: 'Cayman Islands',
		caf: 'Central African Republic',
		tcd: 'Chad',
		chl: 'Chile',
		chn: 'China',
		hkg: 'Hong Kong',
		mac: 'Macao',
		cxr: 'Christmas Island',
		cck: 'Cocos (Keeling) Islands',
		col: 'Colombia',
		com: 'Comoros',
		cog: 'Congo',
		cod: 'The Democratic republic of the Congo',
		cok: 'Cook Islands',
		cri: 'Costa Rica',
		civ: 'Côte d\'Ivoire',
		hrv: 'Croatia',
		cub: 'Cuba',
		cyp: 'Cyprus',
		cze: 'Czech Republic',
		dnk: 'Denmark',
		dji: 'Djibouti',
		dma: 'Dominica',
		dom: 'Dominican Republi',
		ecu: 'Ecuador',
		egy: 'Egypt',
		slv: 'El Salvador',
		gnq: 'Equatorial Guinea',
		eri: 'Eritrea',
		est: 'Estonia',
		eth: 'Ethiopia',
		flk: 'Falkland Islands',
		fro: 'Faroe Islands',
		fji: 'Fiji',
		fin: 'Finland',
		fra: 'France',
		guf: 'French Guiana',
		pyf: 'French Polynesia	',
		atf: 'French Southern Territories',
		gab: 'Gabon',
		gmb: 'Gambia',
		geo: 'Georgia',
		deu: 'Germany',
		gha: 'Ghana',
		gib: 'Gibraltar	',
		grc: 'Greece',
		grl: 'Greenland',
		grd: 'Grenada',
		glp: 'Guadeloupe',
		gum: 'Guam',
		gtm: 'Guatemala',
		ggy: 'Guernsey',
		gin: 'Guinea',
		gnb: 'Guinea-Bissau',
		guy: 'Guyana',
		hti: 'Haiti',
		hmd: 'Heard Island and McDonald Islands',
		vat: 'Holy See ',
		hnd: 'Honduras',
		hun: 'Hungary',
		isl: 'Iceland',
		ind: 'India',
		idn: 'Indonesia',
		irn: 'Iran',
		irq: 'Iraq',
		irl: 'Ireland',
		imn: 'Isle of Man',
		isr: 'Israel',
		ita: 'Italy',
		jam: 'Jamaica',
		jpn: 'Japan',
		jey: 'Jersey',
		jor: 'Jordan',
		kaz: 'Kazakhstan',
		ken: 'Kenya',
		kir: 'Kiribati',
		prk: 'The Democratic People\'s Republic of Korea',
		kor: 'The Republic of Korea',
		kwt: 'Kuwait',
		kgz: 'Kyrgyzstan',
		lao: 'Lao People\'s Democratic Republic',
		lva: 'Latvia',
		lbn: 'Lebanon',
		lso: 'Lesotho',
		lbr: 'Liberia',
		lby: 'Libya',
		lie: 'Liechtenstein',
		ltu: 'Lithuania',
		lux: 'Luxembourg',
		mkd: 'Republic of North Macedonia',
		mdg: 'Madagascar',
		mwi: 'Malawi',
		mys: 'Malaysia',
		mdv: 'Maldives',
		mli: 'Mali',
		mlt: 'Malta',
		mhl: 'Marshall Islands',
		mtq: 'Martinique',
		mrt: 'Mauritania',
		mus: 'Mauritius',
		myt: 'Mayotte',
		mex: 'Mexico',
		fsm: 'Micronesia',
		mda: 'Moldova',
		mco: 'Monaco',
		mng: 'Mongolia',
		mne: 'Montenegro',
		msr: 'Montserrat',
		mar: 'Morocco',
		moz: 'Mozambique',
		mmr: 'Myanmar',
		nam: 'Namibia',
		nru: 'Nauru',
		npl: 'Nepal',
		nld: 'Netherlands',
		ant: 'Netherlands Antilles',
		ncl: 'New Caledonia',
		nzl: 'New Zealand',
		nic: 'Nicaragua',
		ner: 'Niger',
		nga: 'Nigeria',
		niu: 'Niue',
		nfk: 'Norfolk Island',
		mnp: 'Northern Mariana Islands',
		nor: 'NOrway',
		omn: 'Oman',
		plw: 'Palau',
		pak: 'Pakistan',
		pse: 'Palestine',
		pan: 'Panama',
		png: 'Papua New Guinea',
		pry: 'Paraguay',
		per: 'Peru',
		phl: 'Pgilippines',
		pcn: 'Pitcairn',
		pol: 'Poland',
		prt: 'Portugal',
		pri: 'Puerto Rico',
		qat: 'Qatar',
		reu: 'Réunion',
		rou: 'Romania',
		rus: 'Russian Federation',
		rwa: 'Rwanda',
		blm: 'Saint Barthélemy	',
		shn: 'Saint Helena, Ascension and Tristan da Cunha',
		kna: 'Saint Kitts and Nevis',
		lca: 'Saint Lucia',
		maf: 'Saint Martin',
		spm: 'Saint Pierre and Miquelon	',
		vct: 'Saint Vincent and the Grenadines',
		wsm: 'Samoa',
		smr: 'San Marino',
		stp: 'Sao Tome and Principe',
		sau: 'Saudi Arabia',
		sen: 'Senegal',
		srb: 'Serbia',
		syc: 'Seychelles',
		sle: 'Sierra Leone',
		sgp: 'Singapore',
		svk: 'Slovakia',
		svn: 'Slovenia',
		slb: 'Solomon Islands',
		som: 'Somalia',
		zaf: 'South Africa',
		sgs: 'South Georgia and the South Sandwich Islands',
		ssd: 'South Sudan',
		esp: 'Spain',
		lka: 'Sri Lanka',
		sdn: 'Sudan',
		sur: 'Suirname',
		sjm: 'Svalbard and Jan Mayen',
		swz: 'Eswatini',
		swe: 'Sweden',
		che: 'Switzerland',
		syr: 'Syria',
		twn: 'Taiwan',
		tjk: 'Tajikistan',
		tza: 'Tanzania',
		tha: 'Thailand',
		tls: 'Timor-Leste',
		tgo: 'Togo',
		tkl: 'Tokelau',
		ton: 'Tonga',
		tto: 'Trinidad and Tobago',
		tun: 'Tunisia',
		tur: 'Turkey',
		tkm: 'Turkmenistan',
		tca: 'Turks and Caicos Islands',
		tuv: 'Tuvalu',
		uga: 'Uganda',
		ukr: 'Ukraine',
		are: 'United Arab Emirates',
		gbr: 'United Kingdom of Great Britain and Northern Ireland',
		usa: 'United States of America',
		umi: 'United States Minor Outlying Islands',
		ury: 'Uruguay',
		uzb: 'Uzbekistan',
		vut: 'Vanuatu',
		ven: 'Venezuela',
		vnm: 'Viet Nam',
		vir: 'Virgin Islands',
		wlf: 'Wallis and Futuna',
		esh: 'Western Sahara',
		yem: 'Yemen',
		zmb: 'Zambia',
		zwe: 'Zimbabwe',
		// xkx: 'Missing translation',  Note: Kosovo is not listed as an ISO standard country. The unofficial 2 and 3-digit codes are used by the European Commission and others until Kosovo is assigned an ISO code.
	},
	participantOnboardingPage: {
		metaTitle: 'Onboarding',
		forms: {
			onboarding: {
				title: { normalTitle: 'What is ', coloredTitle: 'your company' },
				companyName: {
					label: 'Company name',
					placeholder: 'Aquga',
				},
				vatId: {
					label: 'VAT Number',
					placeholder: '321456987',
				},
				industries: {
					automotive: 'Automotive',
					mechanical_electrical_engineering: 'Mechanical/Electrical Engineering',
					chemistry_pharmaceuticals: 'Chemistry/Pharmaceuticals',
					consumer: 'Consumer',
					trade_retail: 'Trade/Retail',
					logistics: 'Logistics',
					service: 'Service',
					banking_insurance: 'Banking/Insurance',
					energy: 'Energy',
					aerospace_and_defense: 'Aerospace and Defense',
				},
				buttonText: 'Go to company location',
				industryField: 'Select industry',
			},
			companyLocation: {
				title: { normalTitle: 'Company location ', coloredTitle: 'details' },
				city: {
					label: 'City',
					placeholder: 'Leipzig',
				},
				zipCode: {
					label: 'ZIP Code',
					placeholder: '12345',
				},
				streetName: {
					label: 'Street Address',
					placeholder: 'Kronenplatz',
				},
				houseNumber: {
					label: 'Street Number',
					placeholder: '32',
				},
				addressLine2: {
					label: 'Street address line 2',
					placeholder: 'Optional',
				},
				buttonText: 'Go to point of contact',
				country: 'Select country',
			},
		},
	},
	participantRejectedPage: {
		meta: 'You do not have permission to access the platform - AQUGA',
		title: 'You did not fulfill all requirements to gain access to AQUGA',
		body: 'If you think this is a mistake, please reach out via email',
	},
	participantAccount: {
		companyInfo: {
			title: 'Company info',
		},
		paymentDetailsSection: {
			title: 'Payment details',
			vbanDetails: {
				type: { title: 'Type' },
				iban: { title: 'IBAN' },
				bic: { title: 'BIC' },
				country: { title: 'Country' },
				sepa: { title: 'SEPA Bank transfers' },
				price: { title: 'Price' },
				tax: { title: 'Tax' },
				amount: { title: 'Total' },
				reference: { title: 'Reference number' },
			},
		},
	},
};

export default messages_en;
