import { EXTERNAL_URLS, LAYOUT_PADDING } from '@aquga/app/constants';
import LowerFooterSegment from '@aquga/components/layout/footer/LowerFooterSegment';
import { AQUGA_PURPLE_50, AQUGA_YELLOW_30 } from '@aquga/styles/theme';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
	Divider,
	IconButton,
	Link as MUILink,
	Stack,
	styled,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';

const StyledDiv = styled('div')(() => ({
	backgroundColor: AQUGA_PURPLE_50,
	padding: LAYOUT_PADDING,
	width: '100%',
	color: 'white',
}));

function Footer() {
	const theme: Theme = useTheme();
	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	return (
		<footer>
			<StyledDiv>
				<Grid container spacing={4}>
					<Grid item xs={12} textAlign="center">
						<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
							<FormattedMessage id="footer.title" />
						</Typography>
					</Grid>
					<Grid item xs={12} textAlign="center" alignItems="center">
						<Stack
							direction={largeScreen ? 'row' : 'column'}
							width="100%"
							justifyContent={largeScreen ? 'space-between' : 'center'}
							spacing={4}
						>
							<Stack direction="column" textAlign={largeScreen ? 'left' : 'center'} spacing={2}>
								<MUILink
									href={EXTERNAL_URLS.AQUGA_CONTACT_US}
									sx={{ textDecoration: 'none' }}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Typography variant="body1" color="white">
										<FormattedMessage id="footer.contactUs" />
									</Typography>
								</MUILink>
								<MUILink
									href={EXTERNAL_URLS.AQUGA_FAQ}
									sx={{ textDecoration: 'none' }}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Typography variant="body1" color="white">
										<FormattedMessage id="footer.faq" />
									</Typography>
								</MUILink>
							</Stack>
							<Stack
								direction={largeScreen ? 'row' : 'column'}
								alignItems="center"
								justifyContent={largeScreen ? 'flex-end' : 'center'}
							>
								<Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
									<FormattedMessage id="footer.followUs" />
								</Typography>
								<a
									href={EXTERNAL_URLS.AQUGA_LINKEDIN}
									target="_blank"
									style={{ textDecoration: 'none' }}
									rel="noreferrer"
								>
									<IconButton aria-label="Find AQUGA on LinkedIn" sx={{ borderRadius: 0 }}>
										<LinkedInIcon sx={{ color: 'white', fontSize: '3rem' }} />
									</IconButton>
								</a>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
				<Divider
					sx={{
						backgroundColor: AQUGA_YELLOW_30,
						marginTop: theme.spacing(2),
						marginBottom: theme.spacing(4),
					}}
				/>
				<LowerFooterSegment />
			</StyledDiv>
		</footer>
	);
}

export default Footer;
