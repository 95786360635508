import { Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface PaymentDetailsViewProps {
	loading: boolean;
	paymentMethodDetails: any;
	ticketNumber: number;
	price: number;
	tax: number;
	totalToPay: number;
}

export const PaymentDetailsView = ({
	paymentMethodDetails,
	price,
	totalToPay,
	tax,
	ticketNumber,
	loading,
}: PaymentDetailsViewProps) => {
	const intl = useIntl();

	const formattedPrice = intl.formatNumber(price / 100, {
		style: 'currency',
		currency: paymentMethodDetails?.currency ?? 'EUR',
		currencyDisplay: 'code',
	});

	const formattedTax = intl.formatNumber(tax / 100, {
		style: 'currency',
		currency: paymentMethodDetails?.currency ?? 'EUR',
		currencyDisplay: 'code',
	});
	const formattedTotalToPay = intl.formatNumber(totalToPay / 100, {
		style: 'currency',
		currency: paymentMethodDetails?.currency ?? 'EUR',
		currencyDisplay: 'code',
	});

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
			{loading ? (
				<>
					<Skeleton width={120} />
					<Skeleton width={120} />
					<Skeleton width={120} />
				</>
			) : (
				<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
					<Grid item xs={4}>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.type.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.iban.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.bic.title',
							})}
						</Typography>
						<Divider flexItem sx={{ mt: 0.5, mb: 0.7 }} />
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.price.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.tax.title',
							})}
						</Typography>
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.amount.title',
							})}
						</Typography>
						<Divider flexItem sx={{ mt: 0.5, mb: 0.7 }} />
						<Typography>
							{intl.formatMessage({
								id: 'participantAccount.paymentDetailsSection.vbanDetails.reference.title',
							})}
						</Typography>
					</Grid>
					<Grid item xs={8} sx={{ wordBreak: 'break-all' }}>
						<Typography>
							{paymentMethodDetails?.type
								? intl.formatMessage({
									id: 'participantAccount.paymentDetailsSection.vbanDetails.sepa.title',
								  })
								: '-'}
						</Typography>
						<Typography>{paymentMethodDetails?.financial_addresses?.[0]?.iban?.iban ?? '-'}</Typography>
						<Typography>{paymentMethodDetails?.financial_addresses?.[0]?.iban?.bic ?? '-'}</Typography>
						<Divider flexItem sx={{ mt: 0.5, mb: 0.7 }} />
						<Typography>{formattedPrice}</Typography>
						<Typography>{formattedTax}</Typography>
						<Typography fontWeight={600}>{formattedTotalToPay}</Typography>
						<Divider flexItem sx={{ mt: 0.5, mb: 0.7 }} />
						<Typography>{ticketNumber ?? '-'}</Typography>
					</Grid>
				</Grid>
			)}
		</Stack>
	);
};
